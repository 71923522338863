/**
 * Code name: Kingston.
 * Handles MP calls as a wrapper.
 **/
import * as mixpanel from "mixpanel-browser";
import type { Mixpanel, Dict } from "mixpanel-browser";
import { rendererIsClient } from "../scripts/utils";

export interface ExtendedMixpanel extends Mixpanel {
  superProps: { [key: string]: string | boolean | number };
}

declare global {
  interface Window {
    mixpanel: ExtendedMixpanel;
    MIXPANEL_API_TOKEN: string;
    TRACKING_OFF: boolean;
  }
}

if (
  rendererIsClient() &&
  typeof window !== "undefined" &&
  !window?.MIXPANEL_API_TOKEN
) {
  throw new Error("Error, Mixpanel was not loaded due to missing API token.");
}

if (
  rendererIsClient() &&
  typeof window !== "undefined" &&
  window.TRACKING_OFF
) {
  throw new Error(
    "Error, Mixpanel does not load due to tracking being disabled."
  );
}

mixpanel.init(
  rendererIsClient() && typeof window !== "undefined"
    ? window?.MIXPANEL_API_TOKEN
    : ""
);

if (
  rendererIsClient() &&
  typeof window !== "undefined" &&
  window?.mixpanel?.superProps
) {
  try {
    mixpanel.register(window.mixpanel.superProps);
  } catch (e) {
    throw new Error("Error registering superproperties.");
  }
}

export const trackMixpanelEvent = async (
  eventName: string,
  trackerObj: mixpanel.Dict | undefined
): Promise<void> => {
  try {
    return mixpanel.track(eventName, trackerObj);
  } catch (e) {
    throw new Error("Error tracking event.");
  }
};

export const trackMixpanelLinks = async (
  selector: string,
  eventName: string,
  trackerObj?: mixpanel.Dict | (() => void) | undefined
): Promise<void> => {
  try {
    return mixpanel.track_links(selector, eventName, trackerObj);
  } catch (e) {
    throw new Error(e);
  }
};

export const trackMixpanelCharge = async (
  price: number,
  trackerObj: Dict | undefined
): Promise<void> => {
  try {
    return mixpanel.people.track_charge(price, trackerObj);
  } catch (e) {
    throw new Error("Error tracking charge.");
  }
};

export const setMixpanelPeopleProperty = async <T>(
  property: string,
  value: T
): Promise<void> => {
  try {
    return mixpanel.people.set(property, value);
  } catch (e) {
    throw new Error("Error setting people property.");
  }
};

export default trackMixpanelEvent;
