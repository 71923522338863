import { useState, useLayoutEffect } from "react";

export function useOutOfViewport(id: string) {
  const [bounding, setBounding] = useState<undefined | DOMRect>()

  useLayoutEffect(() => {
    function getBounding() {
      const elem = document.getElementById(id)?.getBoundingClientRect();
      if (elem) {
        setBounding(elem)
      }
    }

    window.addEventListener("load", getBounding)
    window.addEventListener("scroll", getBounding)
    window.addEventListener("resize", getBounding)
    return () => {
      window.removeEventListener("load", getBounding)
      window.removeEventListener("scroll", getBounding)
      window.removeEventListener("resize", getBounding)
    }
  }, [id])

  if (!bounding) {
    return {
      top: null, left: null, bottom: null, right: null, any: null, all: null
    }
  }

	const out = {
    top: bounding.top < 0,
    left: bounding.left < 0,
    bottom: bounding.bottom > (window.innerHeight || document.documentElement.clientHeight),
    right: bounding.right > (window.innerWidth || document.documentElement.clientWidth),
  };
  
	return {
    ...out,
    all: out.top && out.left && out.bottom && out.right,
    any: out.top || out.left || out.bottom || out.right,
  };
}