import Dialog from "SKNUI/yodel/yodel";
import { closeModal } from "SKNUI/yodel/utils";

import { Props as LGProps } from "./lead-gen";
import { Submission } from "./interfaces";
import { PFLogo } from "./pf-logo";
import { TT } from "./modal-tooltip";
import { stats, subheading, successBlurb } from "./copy";
import { Form } from "./modal-form";
import { StatsList } from "./stats-list";

export interface ModalProps extends Omit<LGProps, "userInfo"> {
  submitted: boolean;
  busy: boolean;
  submit: (submission: Submission) => void;
  error: boolean;
  userFname?: string;
  defaultLocale: string;
  userSubmission: Submission | undefined;
}

export const modalID = "dialog-lead-gen";
export const btnclass = "alans-butt alans-butt--black Dialog-button";
const bucket = "https://res.cloudinary.com/hnpb47ejt/image/upload/";

export default function LeadGenModal(props: ModalProps) {
  const { submitted, userFname } = props;
  const { name, duration, type, slug } = props.partnerProgram;

  return (
    <Dialog
      id={modalID}
      baseModifierClass={`LeadGenModal LeadGenModal--${
        submitted ? "dead" : "live"
      }`}
      trackingName="Penn Foster Lead-Gen"
    >
      <div className="LeadGenModal__topbar">
        <PFLogo /> <TT />
      </div>
      <div className="LeadGenModal__content">
        <div className="Dialog-title">{`Explore the Penn Foster ${name} program`}</div>
        <div className={!submitted ? "mobile-only" : undefined}>
          {subheading(submitted, userFname)}
        </div>
        {submitted && <p>{successBlurb(name)}</p>}
        {!submitted && <StatsList stats={stats} />}
        {!submitted && (
          <div className="LeadGenModal__card">
            <div className="LeadGenModal__card__thumb">
              <img
                src={bucket + `v1646258562/lead-gen/thumbnails/${slug}`}
                alt=""
                role="presentation"
              />
            </div>
            <div className="LeadGenModal__card__content">
              <h3>{name}</h3>
              <h4>{type}</h4>
              <h5>
                <strong>Duration</strong> {duration[0]}–{duration[1]} months
              </h5>
            </div>
          </div>
        )}
        {submitted && (
          <button className={btnclass} onClick={() => closeModal(modalID)}>
            Back to browsing careers
          </button>
        )}
      </div>
      {!submitted && <Form {...props} />}
    </Dialog>
  );
}
