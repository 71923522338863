import { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";

export type WhichCTA = 1 | 2 | 3;

interface Props {
  whichCTA: WhichCTA;
  careerName?: string;
}

interface State {
  showCTA: boolean;
  isDismissed: boolean;
}

export default class MobileSticky extends Component<Props, State> {
  public state: State = {
    showCTA: false,
    isDismissed: false,
  };

  public componentDidMount(): void {
    this.bindScrollEvents();
  }

  public toggleCTA(isDismissed: boolean): void {
    this.setState({
      showCTA: !this.state.showCTA,
      isDismissed: isDismissed,
    });
  }

  public bindScrollEvents(): void {
    window.addEventListener("scroll", (): void => this.showOnScroll());
  }

  public showOnScroll(): void {
    let top = 450;
    let bottom = document.documentElement.scrollHeight - top;
    let y = window.scrollY;
    let scrollThreshold = y > top && y < bottom;
    if (scrollThreshold !== this.state.showCTA && !this.state.isDismissed) {
      this.toggleCTA(false);
    }
  }

  public render() {
    return this.state.showCTA ? (
      this.props.whichCTA === 1 && this.props.careerName ? (
        <div className="MobileSticky MobileSticky--stuck">
          <button
            className="MobileSticky__close"
            onClick={(): void => this.toggleCTA(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <p>
            <strong>Would you be a good {this.props.careerName}?</strong>
          </p>
          <p>
            <a
              href="/members/register/deferred/"
              className="alans-butt--black MobileSticky__button"
            >
              Take the free career test
            </a>
            <a href="/career-test/" className="MobileSticky__link">
              Learn more about the career test
            </a>
          </p>
        </div>
      ) : this.props.whichCTA === 2 ? (
        <div className="MobileSticky-Tile MobileSticky--stuck">
          <p>
            <strong className="MobileSticky__title">
              The world’s #1 career aptitude test. It’s free.
            </strong>
          </p>
          <p>
            <a
              href="/members/register/deferred/"
              className="alans-butt--black MobileSticky__button"
            >
              Do it now
            </a>
          </p>
        </div>
      ) : this.props.whichCTA === 3 ? (
        <div className="MobileSticky-Slim MobileSticky--stuck">
          <a
            href="/members/register/deferred/"
            className="alans-butt--black MobileSticky__button"
          >
            Take the free career test
          </a>
          <a
            href="/career-test/"
            className="alans-butt--grey MobileSticky__button"
          >
            Learn more about the career test
          </a>
        </div>
      ) : (
        ""
      )
    ) : (
      ""
    );
  }
}
