///////////////////////////////////
//  Career Pages Unified Bundle  //
///////////////////////////////////
import MobileSticky, { WhichCTA } from "SKNUI/mobile-sticky-cta/mobile-sticky";
import TableOfContents from "SKNUI/table-of-contents/table-of-contents";
import { BackToTop } from "SKNUI/table-of-contents/back-to-top";
import UpNextCard from "SKNUI/up-next-card/up-next-card";
import SaveButton from "SKNUI/career/save-button";
import FeedbackButtons from "SKNUI/career/feedback-buttons";
import InfoDialog from "Static/components/Dialogs/info-dialog";
import Stars from "SKNUI/stars/stars";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/pro-solid-svg-icons";

import {
  renderAllToContainer,
  renderComponentToDOM,
  renderToContainer,
} from "SKNUI/scripts/utils";

import { trackMixpanelEvent } from "SKNUI/mixpanel/mixpanel";
import { LeadGen } from "SKNUI/lead-gen";

// global
renderComponentToDOM(TableOfContents, "table-of-contents-container");
renderComponentToDOM(BackToTop, "back-top-container");

const mobileChicletNav = document.getElementById("mobile-chiclet-nav");
if (mobileChicletNav) {
  const mobileChicletNavWrapper = document.getElementById(
    "mobile-chiclet-nav-wrapper"
  );
  document.addEventListener("touchmove", () => {
    const { offsetWidth, scrollLeft, scrollWidth } = mobileChicletNav;
    if (offsetWidth + scrollLeft >= scrollWidth) {
      mobileChicletNavWrapper?.classList.add(
        "chiclet-nav__wrapper--hide-indicator"
      );
    } else {
      mobileChicletNavWrapper?.classList.remove(
        "chiclet-nav__wrapper--hide-indicator"
      );
    }
  });
}

function upNextCardEntry(props: {
  title: string;
  variant: string;
  copy: string;
  ctalink: string;
}) {
  return <UpNextCard {...props} />;
}

renderComponentToDOM(upNextCardEntry, "up-next-container");

// take test cta
function mobileStickyEntry(props: {
  whichCTA: string;
  sectionName: string;
  careerName: string;
}) {
  trackMixpanelEvent("View Career Section", {
    Section: props.sectionName,
    Career: props.careerName,
    "Feature Version": "2019 Career Page Revamp",
  });
  if (localStorage) {
    let whichCTA = localStorage ? localStorage.getItem("cPmSpC") : null;
    let intWhichCTA = whichCTA ? +whichCTA : 0;
    !whichCTA || intWhichCTA >= 3 ? (intWhichCTA = 1) : intWhichCTA++;
    if (whichCTA) {
      localStorage.setItem("cPmSpC", whichCTA.toString());
    }
    return <MobileSticky whichCTA={(whichCTA ?? intWhichCTA) as WhichCTA} />;
  }
  return <></>;
}

window.addEventListener("load", () => {
  renderComponentToDOM(mobileStickyEntry, "mobile-sticky-container");
});

renderAllToContainer(
  <Stars score={5} uid="to-be-cloned" />,
  '[data-component="stars"]'
);

renderToContainer(<InfoDialog />, "info-dialog-container");

renderAllToContainer(
  <SaveButton careerId={0} showText={false} />,
  '[data-component="save-button"]'
);

renderAllToContainer(
  <FeedbackButtons careerName={""} careerSectionSlug={""} />,
  '[data-component="feedback-buttons"]'
);

// icon loading
renderAllToContainer(<FontAwesomeIcon icon={faArrowUp} />, ".ce-arrow-up");
renderAllToContainer(<FontAwesomeIcon icon={faArrowDown} />, ".ce-arrow-down");

renderComponentToDOM(LeadGen, "penn-foster-lead-gen");
