import { Props as StatsListProps } from "./stats-list";

export const bucket =
  "https://res.cloudinary.com/hnpb47ejt/image/upload/v1646084422/";
export const icons = {
  online: bucket + "wa3abm4cgtfkrh6erlnp",
  accredited: bucket + "wast5wwpwuxptztwmpgz",
  selfpaced: bucket + "fzrgbmpktqkryashrozs",
  affordable: bucket + "tjm3i3eg1hbkelkhz9xb",
};
export const stats: StatsListProps["stats"] = [
  ["Online", "Access courses 24/7 from any device", icons.online],
  [
    "Accredited",
    "Recognized certificate, diploma, and degree programs",
    icons.accredited,
  ],
  [
    "Self-paced",
    "Learn on your own time, around your schedule",
    icons.selfpaced,
  ],
  [
    "Affordable",
    "Enjoy flexible, customized payment options",
    icons.affordable,
  ],
];
export const successBlurb = (career: string) =>
  `The Penn Foster team is looking forward to chatting about the ${career} program with you soon.`;
export const disclaimer = (
  <>
    By clicking the request info button, I directly authorize CareerExplorer to
    share my personal information with Penn Foster, Inc. and its affiliated
    entities. I understand that Penn Foster may call and/or text me about
    educational services and for related purposes at the phone number provided,
    including a wireless number, using automated technology. I understand that I
    am not required to provide this consent to attend Penn Foster. As an
    alternative to providing this consent, you may receive information and/or
    enroll in a Penn Foster program by calling{" "}
    <a href="tel:1-877-341-2884" style={{ whiteSpace: "nowrap" }}>
      1-877-341-2884
    </a>
    .
  </>
);

export const subheading = (submitted: boolean, name?: string) => (
  <h2 className="LeadGenModal__heading">
    {!submitted && `Receive free program information today!`}
    {submitted && `Thanks for sending your information, ${name}!`}
  </h2>
);

export const locales = [
  { name: "Alabama", id: "AL" },
  { name: "Alaska", id: "AK" },
  { name: "Arizona", id: "AZ" },
  { name: "Arkansas", id: "AR" },
  { name: "California", id: "CA" },
  { name: "Colorado", id: "CO" },
  { name: "Connecticut", id: "CT" },
  { name: "Delaware", id: "DE" },
  { name: "District of Columbia", id: "DC" },
  { name: "Florida", id: "FL" },
  { name: "Georgia", id: "GA" },
  { name: "Hawaii", id: "HI" },
  { name: "Idaho", id: "ID" },
  { name: "Illinois", id: "IL" },
  { name: "Indiana", id: "IN" },
  { name: "Iowa", id: "IA" },
  { name: "Kansas", id: "KS" },
  { name: "Kentucky", id: "KY" },
  { name: "Louisiana", id: "LA" },
  { name: "Maryland", id: "MD" },
  { name: "Maine", id: "ME" },
  { name: "Massachusetts", id: "MA" },
  { name: "Michigan", id: "MI" },
  { name: "Minnesota", id: "MN" },
  { name: "Mississippi", id: "MS" },
  { name: "Missouri", id: "MO" },
  { name: "Montana", id: "MT" },
  { name: "Nebraska", id: "NE" },
  { name: "Nevada", id: "NV" },
  { name: "New Hampshire", id: "NH" },
  { name: "New Jersey", id: "NJ" },
  { name: "New Mexico", id: "NM" },
  { name: "New York", id: "NY" },
  { name: "North Carolina", id: "NC" },
  { name: "North Dakota", id: "ND" },
  { name: "Ohio", id: "OH" },
  { name: "Oklahoma", id: "OK" },
  { name: "Oregon", id: "OR" },
  { name: "Pennsylvania", id: "PA" },
  { name: "Rhode Island", id: "RI" },
  { name: "South Carolina", id: "SC" },
  { name: "South Dakota", id: "SD" },
  { name: "Tennessee", id: "TN" },
  { name: "Texas", id: "TX" },
  { name: "Utah", id: "UT" },
  { name: "Vermont", id: "VT" },
  { name: "Virginia", id: "VA" },
  { name: "Washington", id: "WA" },
  { name: "West Virginia", id: "WV" },
  { name: "Wisconsin", id: "WI" },
  { name: "Wyoming", id: "WY" },
  { name: "Armed Forces Africa", id: "AE" },
  { name: "Armed Forces America", id: "AA" },
  { name: "Armed Forces Canada", id: "AE" },
  { name: "Armed Forces Europe", id: "AE" },
  { name: "Armed Forces Middle East", id: "AE" },
  { name: "Armed Forces Pacific", id: "AP" },
];
