import * as Sentry from "@sentry/browser";
import { Integration } from "@sentry/types";
import { rendererIsClient, pickUserFromDjango } from "./utils";

declare global {
  interface Window {
    SENTRY_JS_DSN: string;
    GIT_HEAD_HASH: string;
  }
}

/**
 * user error that we don't need to be logging
 */
const userIgnores = [
  "Please enter a valid email address from your educational institution.",
];

/** From Sentry Docs: common ignores
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 */
const commonIgnores = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
];

/**
 * Triggered by chrome extensions, despite toggling on the
 * "Filter out errors known to be caused by browser extensions"
 * option in sentry settings
 * */
const extensionIgnores = [
  "Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target",
  "iTOErtJhcPy is not defined",
  // HeyTapBrowser Chrome WebView
  "TypeError: Cannot read property 'getReadModeExtract' of undefined",
  "TypeError: Cannot read property 'getReadModeRender' of undefined",
  "TypeError: Cannot read property 'getReadModeConfig' of undefined",
  // Pocket app
  "pktAnnotationHighlighter",
  // Honey Safari extension
  "undefined is not an object (evaluating 'g.toString')",
  "undefined is not an object (evaluating 'p.toString')",
];

const fetchErrors = [
  // https://stackoverflow.com/a/60860369/7264754
  "TypeError: Failed to fetch",
  "Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "TypeError: cancelled",
  "cancelled",
  "The internet connection appears to be offline",
  "The Internet connection appears to be offline.", // uhg of course apple had to capitalize Internet
  "The network connection was lost",
  // localizations of the above
  "TypeError: annulé",
  "TypeError: Đã mất kết nối mạng.",
  "TypeError: Veza s mrežom je prekinuta.",
  "キャンセルしました",
  "Έληξε το χρονικό όριο του αιτήματος.",
  "未能找到使用指定主机名的服务器。",
  "avbruten",
  "đã hủy",
  "취소됨",
  "annullato",
  "cancelado",
  "בוטל",
  "anulat",
  "zrušené",
  "A conexão à internet parece estar desativada.",
  "Esgotou-se o tempo limite da solicitação.",
  "Сетевое соединение потеряно.",
  "La connexion réseau a été perdue.",
  "A ligação à rede foi interrompida.",
  "No se ha podido completar la operación. Error del protocolo",
  "De netwerkverbinding is verbroken.",
  "Pripojenie k sieti bolo stratené.",
  "A conexão de rede foi perdida.",
  "ネットワーク接続が切れました。",
  "Die Netzwerkverbindung wurde unterbrochen.",
  "La requête a expiré.",
  "Nätverksanslutningen förlorades.",
  "Zeitüberschreitung bei der Anforderung.",
];

const ignoreErrors = [
  ".translate.goog is not allowed by Access-Control-Allow-Origin.",
  // 'Feature' of ResizeObserver (see: https://stackoverflow.com/a/50387233/7264754)
  "ResizeObserver loop limit exceeded",
  // Another 'feature' (https://webkit.org/blog/9997/resizeobserver-in-webkit/)
  "ResizeObserver loop completed with undelivered notifications.",
  // Purposely triggered by UI (example: search listings)
  "AbortError: The user aborted a request.",
  "AbortError: The operation was aborted.",
  "AbortError: Fetch is aborted", // safari
  "AbortError: Abort due to cancellation of share.", // ios user closing share window
  // Natural occurring for anyone using a private browser or incognito
  "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
  // Natural blocking of client disallowing autoplay of <video>
  "NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
  "NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
  // Not errors. Reduce noise and save our quota
  `{"message":"That email is already in use"}`,
  `{"next_url":"/members/register/?error=Google account already exists"}`,
  `{"detail":"User is anonymous"}`,
  ...fetchErrors,
  // Firefox feature handling add-ons (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Dead_object)
  "can't access dead object",
  // Another feature of Firefox (https://stackoverflow.com/a/11770124/7264754)
  "SecurityError: The operation is insecure.",
  // Happens in Instagram/Safari when it prevents a popup, in this case google-signin
  "Non-Error exception captured with keys: error",
  "popup_blocked_by_browser", // ^ also this
  // russian crawlers, maybe
  /(.*)Failed to load(.*).ru(.*)/,
  "jQuery is not defined",
  "$ is not defined",
  ...userIgnores,
  ...commonIgnores,
  ...extensionIgnores,
];

const { user } = pickUserFromDjango();

Sentry.init({
  dsn:
    rendererIsClient() && user?.environment.sentryJSDSN
      ? user?.environment.sentryJSDSN
      : "",
  release:
    rendererIsClient() && user?.environment.gitHeadHash
      ? `sokanu@${user?.environment.gitHeadHash}`
      : undefined,
  integrations: (integrations: Integration[]): Integration[] => {
    // integrations will be all default integrations
    return [...integrations];
  },
  environment: rendererIsClient()
    ? user?.environment.runningEnvironment
    : undefined,
  ignoreErrors,
  /** From Sentry Docs: common denies
   * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
   */
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    /m\.facebook\.com/,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

if (user?.email?.trim() !== "") {
  Sentry.setUser({ email: user?.email });
}

export default Sentry;
