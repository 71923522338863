import { ReactNode } from "react";

export interface Props {
  stats: [string, string, string | ReactNode][];
}

export function StatsList({ stats }: Props) {
  return (
    <ul className="LeadGenModal__stats">
      {stats.map(([nom, txt, src]) => (
        <li key={nom}>
          {typeof src === "string" ? <img src={src} alt={nom} /> : src}
          <span>
            <strong>{nom}</strong> <span className="desktop-only">{txt}</span>
          </span>
        </li>
      ))}
    </ul>
  );
}
