import { trackMixpanelEvent } from "../mixpanel/mixpanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/pro-light-svg-icons/faWindowClose";
import { Component, ReactNode } from "react";

export type TooltipPos =
  | "right-top"
  | "left-top"
  | "right-bottom"
  | "left-bottom"
  | "center-bottom";

interface Props {
  id?: string;
  popover?: {
    delay: number;
    /** duration 0 === Infinity */
    duration: number;
  };
  animation?: string;
  popOverCopy?: string;
  expanded: boolean;
  text: string | ReactNode;
  sourceName?: string;
  sourceUrl?: string;
  link?: string;
  linkUrl?: string;
  position?: TooltipPos;
  handleClick?: () => void;
  hideCallback?: () => void;
  showDismissIcon?: boolean;
}

interface State {
  isHidden: boolean;
  popoverRevealTimer: null | number | NodeJS.Timer;
  popoverRemoveTimer: null | number | NodeJS.Timer;
}

export default class Tooltip extends Component<Props, State> {
  _isMounted = false;
  public state: State = {
    isHidden: !this.props.expanded,
    popoverRevealTimer: null,
    popoverRemoveTimer: null,
  };

  public componentDidMount(): void {
    this._isMounted = true;
    if (this.props.popover) {
      this.configurePopover();
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      this.props.popover &&
      prevState.popoverRevealTimer !== this.state.popoverRevealTimer
    ) {
      this.configurePopover();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  public configurePopover(): void {
    if (
      !this.state.popoverRevealTimer &&
      this.props.popover &&
      this.props.popover?.duration !== 0 &&
      this.props.popover?.duration !== Infinity
    ) {
      let revealTimer = setTimeout((): void => {
        if (this._isMounted) {
          this.setState({ isHidden: false });
          trackMixpanelEvent("Save Progress Popover", {
            Copy: this.props.popOverCopy,
          });
        }
      }, this.props.popover.delay);
      this.setState({ popoverRevealTimer: revealTimer });
      let removeTimer = setTimeout((): void => {
        this.setState({ isHidden: true });
        this.props.hideCallback?.();
      }, this.props.popover.delay + this.props.popover.duration);
      this.setState({ popoverRemoveTimer: removeTimer });
    }
  }

  public getSource(): ReactNode {
    if (this.props.sourceName) {
      let text: ReactNode | string = this.props.sourceName;
      if (this.props.sourceUrl) {
        text = <a href={this.props.sourceUrl}>{text}</a>;
      }
      return (
        <div className="Tooltip-source-wrap">
          <span className="Tooltip-source-label">Source:</span>
          <cite className="Tooltip-source" data-testid="tooltip-source">
            {text}
          </cite>
        </div>
      );
    }
    return "";
  }

  public getLink(): ReactNode {
    if (this.props.link && this.props.link.length > 0 && this.props.linkUrl) {
      return (
        <a
          className="Tooltip-link"
          href={this.props.linkUrl}
          data-testid="tooltip-link"
        >
          {" " + this.props.link}
        </a>
      );
    }
    return "";
  }

  public getDisplayClass(): string {
    return this.state.isHidden ? "Tooltip-hidden" : "";
  }

  public getPositionClass(): string {
    return `Tooltip-${
      this.props.position ? this.props.position : "right-bottom"
    }`;
  }

  public getAnimationClass(): string {
    return `animation-${this.props.animation}`;
  }

  public getClassList(): string {
    let classList = `Tooltip`;
    classList = `${classList} ${this.getDisplayClass()}`;
    classList = `${classList} ${this.getPositionClass()}`;
    classList = `${classList} ${this.getAnimationClass()}`;
    return classList;
  }

  public dismissTooltip(): void {
    this.setState({
      isHidden: true,
    });
    this.props.hideCallback?.();
  }

  public render() {
    return (
      <aside
        id={"tooltip-" + this.props.id}
        className={this.getClassList()}
        onClick={this.props.handleClick}
        data-testid="tooltip"
      >
        <div className="Tooltip-wrap">
          {this.props.showDismissIcon ? (
            <div
              className="Tooltip-fa-icon-wrap"
              onClick={this.dismissTooltip.bind(this)}
            >
              <FontAwesomeIcon
                className="Tooltip-fa-window-close-icon"
                icon={faWindowClose}
                size="sm"
              />
            </div>
          ) : (
            ""
          )}
          {typeof this.props.text === "string" ? (
            <p
              className="Tooltip-text"
              suppressHydrationWarning={true}
              data-testid="tooltip-text"
            >
              {this.props.text}
              {this.getLink()}
            </p>
          ) : (
            this.props.text
          )}
          {this.getSource()}
          <span
            className={
              "Tooltip-pointer " + this.getPositionClass() + "-pointer"
            }
          />
        </div>
      </aside>
    );
  }
}
