import { ReactNode, CSSProperties, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { rendererIsClient } from "../scripts/utils";

interface Props {
  links: TocLink[];
  expandable?: boolean;
}

export interface TocLink {
  name: string;
  slug: string;
}

export default function TableOfContents({ links, expandable = true }: Props) {
  const [toggled, setToggled] = useState(false);
  const [maxHeight, setMaxHeight] = useState(75);

  function grabListMaxHeight() {
    const listWrap: HTMLElement | null = document.querySelector<HTMLDivElement>(
      ".TableOfContents"
    );
    if (listWrap) {
      const items = [
        ...Array.from(
          listWrap.querySelectorAll("li") as NodeListOf<HTMLElement>
        ),
      ];
      const links = items.map((li): number => li.offsetHeight);
      const maxHeight = links.reduce(
        (sum, currentVal): number => sum + currentVal,
        0
      );

      setMaxHeight(maxHeight);
    }
  }

  function toggleClass(): string {
    return toggled ? "open" : "closed";
  }

  useEffect(() => {
    if (rendererIsClient() && expandable) {
      window.addEventListener("load", grabListMaxHeight);
    }
    if (!expandable) {
      setToggled(true)
    }
  }, [expandable]);

  const max = toggled ? maxHeight : 75;
  const styles = {
    "--maxHeightDesktop": `${max + 40}px`,
    "--maxHeightMobile": `${max}px`,
  } as CSSProperties;

  return (
    <>
      <div
        className={`TableOfContents TableOfContents--${
          links.length > 6 ? toggleClass() : "inactive"
        }`}
        tabIndex={0}
        role="directory"
        title="Table of contents"
        id="table-of-contents"
      >
        <h3>In this article:</h3>
        <ol style={links.length > 6 ? styles : {}}>
          {links.map(
            (link, index): ReactNode => {
              return (
                <li key={index}>
                  <a href={link.slug}>{link.name}</a>
                </li>
              );
            }
          )}
        </ol>
        {!toggled && links.length > 6 && (
          <button
            onClick={() => setToggled(!toggled)}
            aria-controls="table-of-contents"
          >
            <span>See more</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        )}
      </div>
    </>
  );
}
