import { FormEvent, useState } from "react";
import Arcane from "SKNUI/arcane/arcane";
import { User } from "SKNUI/interfaces/user";
import SelectField from "SKNUI/select-field/select-field";
import TextField from "SKNUI/text-field/text-field";

import { locales, disclaimer, subheading } from "./copy";
import { btnclass, ModalProps } from "./modal";
import { Submission } from "./interfaces";

interface Props extends ModalProps {
  buttonText?: string;
  user?: User;
  userSubmission: Submission | undefined;
}

type SorUndef = string | undefined;

export function Form({
  submit,
  submitted,
  busy,
  error,
  defaultLocale,
  userFname,
  buttonText = "Request free information",
  user,
  userSubmission,
}: Props) {
  const [firstName, setFname] = useState<SorUndef>(
    userSubmission?.firstName ?? user?.name
  );
  const [lastName, setLname] = useState<SorUndef>(
    userSubmission?.lastName ?? user?.lastName
  );
  const [email, setEmail] = useState<SorUndef>(
    userSubmission?.email ??
      (user?.email?.includes("deferred") ? "" : user?.email)
  );
  const [phone, setPhone] = useState<SorUndef>(userSubmission?.phone);
  const [state, setLocale] = useState<string>(defaultLocale);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!busy && firstName && lastName && email && phone && state) {
      submit({ firstName, lastName, email, phone, state });
    }
  }

  return (
    <form onSubmit={handleSubmit} className="LeadGenForm">
      <div className="LeadGenForm__heading desktop-only">
        {subheading(submitted, userFname)}
      </div>
      {error && (
        <p role="alert" className="LeadGenForm__error">
          <span>
            There was an error with the information you inputted. Please try
            again.
          </span>
        </p>
      )}
      <div className="LeadGenForm__wrap">
        <div className="LeadGenForm__wrap__field">
          <Arcane
            error="Please enter a first name"
            label="First name"
            field={
              <TextField
                autocomplete="given-name"
                fieldClass="Dialog-input"
                type="text"
                name="lg-first"
                id="lg-first"
                placeholder="First name"
                required={true}
                value={firstName}
                onChange={(e) => {
                  setFname(e.currentTarget.value);
                }}
              />
            }
          />
        </div>
        <div className="LeadGenForm__wrap__field">
          <Arcane
            error="Please enter a last name"
            label="Last name"
            field={
              <TextField
                autocomplete="family-name"
                fieldClass="Dialog-input"
                type="text"
                name="lg-last"
                id="lg-last"
                placeholder="Last name"
                required={true}
                value={lastName}
                onChange={(e) => {
                  setLname(e.currentTarget.value);
                }}
              />
            }
          />
        </div>
        <div className="LeadGenForm__wrap__field">
          <Arcane
            error="Please enter a valid email"
            label="Email"
            field={
              <TextField
                autocomplete="email"
                fieldClass="Dialog-input"
                type="email"
                name="lg-email"
                id="lg-email"
                placeholder="Email"
                required={true}
                // okay={errors.member__email ? false : true}
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            }
          />
        </div>
        <div className="LeadGenForm__wrap__field">
          <Arcane
            error="Please enter a valid phone number"
            label="Phone number"
            field={
              <TextField
                autocomplete="phone"
                fieldClass="Dialog-input"
                type="text"
                name="lg-phone"
                id="lg-phone"
                placeholder="Phone number"
                required={true}
                value={phone}
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
              />
            }
          />
        </div>
        <div className="LeadGenForm__wrap__field">
          <Arcane
            error="Please enter a valid state"
            label="State"
            field={
              <SelectField
                id="lg-state"
                autocomplete="locale"
                items={locales}
                fieldClass="Dialog-input"
                name="lg-state"
                placeholder="State"
                required={true}
                value={locales.find((l) => l.id === state)?.name}
                onSelect={(it) => {
                  if (it) {
                    setLocale(it.id.toString());
                  }
                }}
              />
            }
          />
        </div>
      </div>
      <p className="LeadGenForm__disclaimer">
        <small>{disclaimer}</small>
      </p>
      <button className={btnclass} style={{ zIndex: 1 }}>
        {!busy ? (!submitted ? buttonText : "Thanks!") : "Sending"}
      </button>
    </form>
  );
}
