import { useState, useLayoutEffect } from "react";

export default function useDeviceWidth(): [number] {
  let [deviceWidth, setDeviceWidth] = useState(0);

  useLayoutEffect((): (() => void | undefined) => {
    function handleResize(): void {
      let elem = document.querySelector("body");
      setDeviceWidth(elem ? elem.clientWidth : 0);
    }

    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect((): (() => void | undefined) => {
    function handleResize(): void {
      let elem = document.querySelector("body");
      setDeviceWidth(elem ? elem.clientWidth : 0);
    }

    window.addEventListener("load", handleResize);
    return (): void => {
      window.removeEventListener("load", handleResize);
    };
  }, []);

  return [deviceWidth];
}
