import { SectionSlug as DegreePageSectionSlug } from "Static/pages/degree-detail/base/context/section-slug";
import { NullState } from "SKNUI/interfaces/class";
import { Component } from "react";

interface Props {
  title: string;
  variant: string;
  copy: string;
  ctalink: string;
}

const careerSectionSlugs = [
  "overview",
  "salary",
  "job-market",
  "how-to-become",
  "jobs",
  "education",
  "resources",
  "satisfaction",
  "personality",
  "demographics",
  "work-environment",
  "compatibility",
  "ask-an-expert",
] as const;

export type CareerPageSectionSlug = typeof careerSectionSlugs[number];

const BlobMap: {
  [key in DegreePageSectionSlug | CareerPageSectionSlug]: number;
} = {
  overview: 1,
  "ask-an-expert": 1,
  salary: 2,
  "job-market": 3,
  "how-to-become": 4,
  jobs: 5,
  education: 6,
  resources: 7,
  satisfaction: 8,
  personality: 9,
  demographics: 10,
  "work-environment": 11,
  compatibility: 12,
  tuition: 3,
  "career-paths": 4,
  // diversity: 5
};

export default class UpNextCard extends Component<Props, NullState> {
  public componentDidMount(): void {
    // stub
  }

  public render() {
    const title = this.props.title
      ? this.props.title.replace("&amp;", "&")
      : "";
    return (
      <div className={`UpNextCard UpNextCard--${this.props.variant}`}>
        <div className="UpNextCard__copy">
          <h2 className="UpNextCard__title">{title}</h2>
          <p className="UpNextCard__description">
            {this.props.copy ? this.props.copy.replace("&amp;", "&") : ""}
          </p>
          <span>
            <a href={this.props.ctalink} className="UpNextCard__cta">
              {this.props.variant === "match-insights"
                ? "Read your match insights"
                : `Read about ${title}`}
            </a>
          </span>
        </div>
        <img
          className="UpNextCard__art lazyload"
          data-src={`/static/compiled/images/up-next-blobs/${
            BlobMap[
              this.props.variant as
                | DegreePageSectionSlug
                | CareerPageSectionSlug
            ]
          }-vert.svg`}
          alt=""
          width="213"
          height="337"
          role="presentation"
          aria-hidden
        />
      </div>
    );
  }
}
