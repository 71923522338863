import Sentry from "../scripts/sentry";
import { getCookie } from "../scripts/utils";

export function handleThrow(error: Error, full_url: string, body: RequestInit) {
  Sentry.setExtra("full_url", full_url);
  Sentry.setExtra("request_body", body.body);
  Sentry.captureException(error);
  throw error;
}

function setTransactionId() {
  const id = Math.random().toString(36).substr(2, 9);
  Sentry.setTag("transaction_id", id);
  return id;
}

export function buildHeaders() {
  const cookie: string | null = getCookie("csrftoken");
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "X-Transaction-ID": setTransactionId(),
  };

  if (cookie) {
    headers["X-CSRFToken"] = cookie;
  }
  return headers;
}
