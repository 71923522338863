import { useLayoutEffect, MouseEvent, useState } from "react";
import { useOutOfViewport } from "SKNUI/scripts/use-out-of-viewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { CSSTransition } from "react-transition-group";

interface Props {
  target?: string;
  text?: string | null;
  icon?: IconDefinition;
}

export function BackToTop({
  target = "table-of-contents-container",
  text = "Top",
  icon = faChevronUp,
}: Props) {
  const [visible, setVisible] = useState(false);
  const { top } = useOutOfViewport(target);

  function scrollTo(e: MouseEvent) {
    e.preventDefault();
    const element = document.getElementById(target);
    window.scrollTo({
      top: window.scrollY + (element?.getBoundingClientRect()?.top ?? 0) - 10,
      behavior: "smooth",
    });
  }

  useLayoutEffect(() => {
    setVisible(!!top);
  }, [top]);

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames="back-to-top"
      unmountOnExit
    >
      <div className="back-to-top">
        <a
          id="back-top"
          href="#table-of-contents"
          aria-label="Back to top"
          title="Jump to table of contents"
          onClick={scrollTo}
          className="BackToTop"
        >
          <FontAwesomeIcon icon={icon} />
          {text}
        </a>
      </div>
    </CSSTransition>
  );
}
