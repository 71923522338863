import { Component, ReactNode } from "react";
import Sentry from "./sentry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartBroken } from "@fortawesome/pro-solid-svg-icons/faHeartBroken";
import { NullState } from "SKNUI/interfaces/class";

interface Props {
  onErrorCallback: (error: Error | null, info: NullState) => void | null;
  FallbackElement: ReactNode;
}

interface State {
  caughtError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  private constructor(props: Props) {
    super(props);
    this.state = {
      caughtError: false,
    };
  }

  public static getDerivedStateFromError(
    error: Error | null
  ): { caughtError: boolean } {
    // Update state so the next render will show the fallback UI.
    if (error) {
      return { caughtError: true };
    }
    return { caughtError: false };
  }

  public componentDidCatch(error: Error | null, info: NullState): void {
    if (this.props.onErrorCallback) {
      this.props.onErrorCallback(error, info);
    }
    this.logErrorToSentry(error);
  }

  private logErrorToSentry(error: Error | null): void {
    Sentry.captureException(error);
  }

  public render() {
    const { FallbackElement } = this.props || null;
    if (this.state.caughtError) {
      if (!FallbackElement) {
        return (
          <div className="ErrorBoundary">
            <div className="ErrorBoundary-content">
              <FontAwesomeIcon
                icon={faHeartBroken}
                className="ErrorBoundary-content-heart faded"
                size="lg"
              />
              <h1 className="ErrorBoundary-content-heading">
                {" "}
                Oops! Something went wrong.
              </h1>
              <h2 className="ErrorBoundary-content-message">
                {" "}
                Try refreshing the page and if the problem persists, send an
                email to <strong>contact@sokanu.com</strong>
              </h2>
            </div>
          </div>
        );
      }
      // custom fallback element for error handling
      return FallbackElement;
    }
    return this.props.children;
  }
}
