import { ReactNode } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faStar as faStarOutlined } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStarHalf } from "@fortawesome/pro-solid-svg-icons/faStarHalf";
import { faStarHalfAlt as faStarHalfOutlined } from "@fortawesome/pro-solid-svg-icons/faStarHalfAlt";

interface DOMStarMap extends StarsProps {
  [name: string]: number | string | undefined | boolean;
}

export interface HTMLStarComponent extends Omit<HTMLDivElement, "dataset"> {
  dataset: DOMStarMap;
}

export interface StarsProps {
  /**
   * From 0 — 10
   */
  score: number;
  size?: SizeProp;
  uid: string; // Required because we often render multiple star components on a page
  customLabel?: string;
  outline?: boolean;
}

const getStars = (
  score: number,
  size: StarsProps["size"],
  uid: string,
  outline: boolean
): ReactNode[] => {
  let array: ReactNode[] = [];

  for (let i = 0; i < Math.floor(score / 2); i++) {
    array.push(
      <FontAwesomeIcon
        className="Stars__star Stars__star--full"
        data-testid="star-full"
        key={`star-${uid}-${array.length}`}
        size={size}
        fixedWidth
        icon={faStar}
      />
    );
  }
  if (score % 2 !== 0) {
    if (outline) {
      array.push(
        <FontAwesomeIcon
          className="Stars__star Stars__star--half"
          data-testid="star-half"
          key={`star-${uid}-${array.length}`}
          icon={faStarHalfOutlined}
        />
      );
    } else {
      array.push(
        <span
          className={`Stars__star Stars__star--half fa-layers fa-fw fa-${size}`}
          data-testid="star-half"
          key={`star-${uid}-${array.length}`}
        >
          <FontAwesomeIcon className="Stars__star--empty" icon={faStar} />
          <FontAwesomeIcon icon={faStarHalf} />
        </span>
      );
    }
  }
  while (array.length < 5) {
    array.push(
      <FontAwesomeIcon
        className={`Stars__star ${outline ? "" : "Stars__star--empty"}`}
        data-testid="star-empty"
        key={`star-${uid}-${array.length}`}
        size={size}
        fixedWidth
        icon={outline ? faStarOutlined : faStar}
      />
    );
  }

  return array;
};

const Stars = (props: StarsProps): JSX.Element => {
  return (
    <div
      className="Stars"
      aria-label={
        props.customLabel
          ? props.customLabel
          : `${Math.round(props.score) / 2} out of 5`
      }
    >
      {getStars(
        Math.round(props.score),
        props.size,
        props.uid,
        props.outline ? props.outline : false
      )}
    </div>
  );
};

export default Stars;
