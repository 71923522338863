import QueryString from "query-string";
import { buildHeaders, handleThrow } from "./utils";

/**
 * Typed fetch method extending HTTP fetch.
 *
 * @param method the type of request
 * @param url a string containing the requested endpoint to hit
 * @param {T} data an object of type T to be passed with the request
 * @param errorCallback the name says it all
 * @param signal AbortSignal for client termination
 *
 * @return {U} A promise containing a json response of type U
 */

export const goFetch = async <T extends unknown, U extends unknown>(
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE",
  url: string,
  data?: T,
  errorCallback?: (response: Response) => void,
  signal?: AbortSignal
) => {
  const headers = buildHeaders();
  let response: Response | undefined;
  let request_url = url;
  let body: RequestInit = {
    method,
    headers,
    credentials: "include",
  };

  if (method === "GET") {
    request_url = `${url}${
      data ? "?" + QueryString.stringify(data as { [key: string]: string }) : ""
    }`;
  } else {
    body.body = JSON.stringify(data);
  }

  try {
    /**
     * Additional try catch to handle immediate fails
     */
    try {
      response = await fetch(request_url, { ...body, signal });
    } catch (error) {
      handleThrow(error, request_url, body);
    }

    /**
     * Handle JSON parsing
     */
    if (
      response?.ok ||
      (response && response?.status >= 200 && response?.status <= 299)
    ) {
      const contentType: string | null = response?.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return response?.json();
      } else if (response?.ok) {
        return new Promise((resolve) => {
          resolve(response as U); // resolve non-json responses
        });
      }
      throw new TypeError("Oops, we haven't got JSON!");
    }

    if (errorCallback && response) {
      errorCallback(response);
    }

    let errorString = `${response?.status}`;
    try {
      errorString = JSON.stringify(await response?.json());
    } catch {
      (): void => {
        // silence 🤫
      };
    }
    throw new Error(errorString);
  } catch (error) {
    handleThrow(error, request_url, body);
  }
};

export default goFetch;
