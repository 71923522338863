import { Component } from "react";
import Dialog from "SKNUI/yodel/yodel";

interface Props {
  title?: string;
  content?: string;
  dialogModifierClass?: string;
  baseModifierClass?: string;
  handleDialogShow?: () => void;
}

interface State {
  title?: string;
  content?: string;
  dialogModifierClass?: string;
}

export default class InfoDialog extends Component<Props, State> {
  public state: State = {
    title: "Lorem ipsum doler sit amet",
    content: "",
    dialogModifierClass: "",
  };

  public componentDidMount(): void {
    const links: NodeListOf<Element> = Array.prototype.slice.call(
      document.querySelectorAll(`[data-dialog-target="dialog-info"]`),
      0
    );

    links.forEach((link): void => {
      link.addEventListener("click", (e: MouseEvent): void => {
        if (e.currentTarget) {
          const dataSet = (e.currentTarget as HTMLElement).dataset;
          if (dataSet) {
            this.setState({
              title: dataSet.title,
              content: dataSet.content,
              dialogModifierClass: dataSet.dialogModifierClass
                ? dataSet.dialogModifierClass
                : "",
            });
          }
        }
      });
    });
  }

  private createMarkup(): { __html: string } {
    return {
      __html: this.props.content
        ? this.props.content
        : this.state.content
        ? this.state.content
        : "",
    };
  }

  public render() {
    const title = this.props.title ? this.props.title : this.state.title;
    const dMClass = this.props.dialogModifierClass
      ? this.props.dialogModifierClass
      : this.state.dialogModifierClass;
    const baseClass = this.props.baseModifierClass
      ? this.props.baseModifierClass
      : undefined;
    return (
      <Dialog
        id="dialog-info"
        baseModifierClass={baseClass ? baseClass : ""}
        dialogModifierClass={dMClass ? dMClass : "Dialog--InfoDialog"}
        wrapModifierClass="Dialog--InfoDialog"
        trackingName={`Info Dialog - ${title}`}
        title={title}
      >
        <div
          className="Dialog-info"
          data-testid="dialog-info"
          dangerouslySetInnerHTML={this.createMarkup()}
        />
      </Dialog>
    );
  }
}
