import QueryString from "query-string";
import goFetch from "SKNUI/fetch/fetch";
import { trackMixpanelEvent as mixpanel } from "SKNUI/mixpanel/mixpanel";
import { Submission } from "./interfaces";

const PF_POST_URL =
  "https://www.pennfosterleads.com/LeadPosting.svc/campaignForm";
const PF_CAMPAIGN_ID = "cbc49956-bc4f-4fce-a85d-8cdc111a3667";
type TrackerObj = Parameters<typeof mixpanel>[1];
const track = (obj: TrackerObj) =>
  mixpanel("Lead Gen Submission Response", obj);
const falseNegative = "The lead information provided is already in our system";

interface Props {
  id: number;
  name: string;
  submission: Submission;

  isTest?: boolean;
}

export async function submitToPennFoster({
  id,
  name,
  submission,
  isTest,
}: Props) {
  track({ name, submission });

  /** Attempt to save, fail silently? */
  goFetch("POST", "/api/v1/lead-gen-user/", {
    first_name: submission.firstName,
    last_name: submission.lastName,
    email: submission.email,
    phone: submission.phone,
    state: submission.state,
  });

  const qs = QueryString.stringify({ campaignId: PF_CAMPAIGN_ID, isTest });
  const request_url = `${PF_POST_URL}${"?" + qs}`;
  let resp = false;

  await fetch(request_url, {
    method: "POST",
    body: new URLSearchParams({
      ddProgramOfInterest: `${id}`,
      txtFName: submission.firstName,
      txtLName: submission.lastName,
      ddState: submission.state,
      txtEmail: submission.email,
      txtPhone1: submission.phone,
      AffGUID: "A498B603-E677-4C51-B038-058465472EFC",
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      const response = JSON.parse(res);

      for (const msg of response) {
        if (msg.Message.includes(falseNegative)) {
          track({
            name,
            responseType: "success",
          });
          resp = true;
          break;
        }
        if (msg.Message.includes("FAILED")) {
          track({
            name,
            responseType: "error",
          });
          resp = false;
          break;
        }
        if (msg.Message.includes("PASSED")) {
          track({
            name,
            responseType: "success",
          });
          resp = true;
          break;
        }
      }
    });

  return resp;
}
