import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faHeart as faHeartHollow } from "@fortawesome/pro-regular-svg-icons/faHeart";
import Tooltip from "SKNUI/tooltip/tooltip";
import { goFetch } from "SKNUI/fetch/fetch";

interface DOMSaveButtonMap extends Props {
  [name: string]: number | boolean | undefined;
}

export interface HTMLSaveButtonComponent extends Omit<HTMLDivElement, "dataset"> {
  dataset: DOMSaveButtonMap;
}

interface Props {
  careerId: number;
  showText: boolean;
  savedId?: number;
}

export default function SaveButton(props: Props) {
  let [saved, setSaved] = useState(props.savedId ? true : false);
  let [savedId, setSavedId] = useState(props.savedId);
  let [hover, setHover] = useState(false);
  let [freshlyClicked, setFreshlyClicked] = useState(false);

  function save(): void {
    try {
      goFetch(
        saved ? "DELETE" : "POST",
        `/api/v2/membercareerfavorites/${
          saved && savedId ? savedId + "/" : ""
        }`,
        {
          career: props.careerId,
        }
      ).then((response: { id?: number }): void => {
        if (saved) {
          setSaved(false);
          setSavedId(-1);
        } else {
          setSaved(true);
          setSavedId(response.id);
          setFreshlyClicked(true);
        }
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  return (
    <>
      <button
        className={`save-button alans-butt--grey alans-butt--grey--hollow ${
          saved ? "save-button--saved" : ""
        }`}
        aria-label="Click to save this career to your favorites"
        onClick={(): void => save()}
        onMouseOver={(): void => setHover(true)}
        onMouseOut={(): void => setHover(false)}
      >
        {saved || hover ? (
          <FontAwesomeIcon icon={faHeart} />
        ) : (
          <FontAwesomeIcon icon={faHeartHollow} />
        )}
        {props.showText ? ` ${saved ? "Saved" : "Save"}` : ""}
      </button>
      {saved && freshlyClicked && (
        <Tooltip
          expanded={true}
          position="right-top"
          text={`You saved this career! See all your saved careers on the `}
          animation="scale-in-right"
          link={`“My Results” page.`}
          linkUrl={"/dashboard"}
          popover={{ delay: 0, duration: 8000 }}
        />
      )}
    </>
  );
}
