/* ============================================
  TextField component can be used as either an
  input or textarea.

  A callback can be passed in the form of
  onChange() in props.

  Form validation should be handled at the form
  level, passing an `okay = true` prop to each
  input.
============================================ */
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  HTMLAttributes,
  AriaAttributes,
  useState,
  useLayoutEffect,
  useRef,
  RefObject,
  useEffect,
  FormEvent,
} from "react";

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { Arcane, Arcana, ArcaneProps } from "SKNUI/arcane/arcane";

export type TextFieldInputType =
  | "text"
  | "textarea"
  | "checkbox"
  | "radio"
  | "number"
  | "date"
  | "email"
  | "password"
  | "tel";

export interface Props {
  /** Purposefully extends specific wia/aria attrs to ensure they are properly consumed */
  role?: HTMLAttributes<HTMLInputElement>["role"];
  "aria-label"?: AriaAttributes["aria-label"];
  "aria-labelledby"?: AriaAttributes["aria-labelledby"];
  "aria-describedby"?: AriaAttributes["aria-describedby"];
  "aria-autocomplete"?: AriaAttributes["aria-autocomplete"];
  "aria-activedescendant"?: AriaAttributes["aria-activedescendant"];
  autocomplete?: boolean | HTMLInputElement["autocomplete"];
  disabled?: boolean;
  enableCharacterCount?: boolean;
  /**
   * use RegExp to display an internal-only transformed text value
   * @example /^(\d{2})(\d{2})$/
   **/
  displayMaskRegExp?: RegExp;
  /**
   * string replacement for `displayMaskRegExp`
   * @example "$1/$2" => "some string/another string"
   **/
  displayMaskRegExpReplace?: string;
  fieldClass?: string;
  /**
   * Note: this method seems to be having trouble rectifying the ShadowDOM vs the DOM `checked` value of inputs
   **/
  handleKeyDown?: (e: KeyboardEvent) => void;
  icon?: IconDefinition | ((focused: boolean) => IconDefinition);
  iconProps?:
    | Omit<FontAwesomeIconProps, "icon">
    | ((focused: boolean) => Omit<FontAwesomeIconProps, "icon">);
  iconPlacement?: "left" | "right";
  id: string;
  name?: string;
  okay?: boolean | void;
  onBlur?: (e: FocusEvent) => void;
  /**
   * duplicate to onTextAreaChange to simplify type checking
   * @hint looking to handle `HTMLTextAreaElement`? Maybe you meant to use `onTextAreaChange`
   */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  /**
   * duplicate to onChange to simplify type checking
   * @hint looking to handle `HTMLInputElement`? Maybe you meant to use `onChange`
   */
  onTextAreaChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClick?: (e: MouseEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  pattern?: string;
  placeholder?: string | ((focused: boolean) => string);
  required?: boolean;
  step?: number;
  max?: string;
  maxLength?: number;
  min?: number | string;
  tabIndex?: number;
  type?: TextFieldInputType;
  value?: string;
  variant?: string;

  // textarea specific
  cols?: number;
  readOnly?: boolean;
  rows?: number;

  /**
   * checkbox/radio specific
   * @hint for this component to properly work `Arcane` must be used to supply a `label`
   */
  checked?: boolean;

  // testing suite specific
  "data-testid"?: string;
  "data-qa-id"?: string;

  /**
   * The presence of an `incantation` implies something `Arcane`
   * @see arcane.tsx
   */
  incantation?: (arcana: Arcana) => void;
}

/**
 * An all in one wrapper for text inputs and textareas
 * @param type defaults to "text"
 */
export default function TextField(props: Props) {
  const mounted = useRef(false);
  const ref = useRef<HTMLElement>(null);

  const checkbox = props.type === "checkbox" || props.type === "radio";
  const [checked, setChecked] = useState(
    props.checked && checkbox ? true : false
  );
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    props.value && props.type !== "checkbox" ? props.value : ""
  );

  function handleOnClick(e: MouseEvent) {
    e.stopPropagation();
    if (checkbox) {
      setChecked((e.currentTarget as HTMLInputElement).checked);
      return props.onClick?.(e);
    }
    if (!focused) {
      setFocused(true);
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    if (checkbox) {
      setChecked((e.currentTarget as HTMLInputElement).checked);
    } else {
      let val = (e.currentTarget as HTMLInputElement).value;
      if (props.maxLength) {
        val = val.substring(0, props.maxLength);
      }
      setValue(val);
    }
    return props.onChange?.(e);
  }

  /**
   * duplicate to handleChange to simplify type checking
   */
  function handleTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    let val = (e.currentTarget as HTMLTextAreaElement).value;
    if (props.maxLength) {
      val = val.substring(0, props.maxLength);
    }
    if (mounted.current) {
      setValue(val);
      return props.onTextAreaChange?.(e);
    }
  }

  function handleFocus(e: FocusEvent) {
    e.stopPropagation();

    if (mounted.current && !checkbox) {
      setFocused(true);
      return props.onFocus?.(e);
    }
  }

  function handleBlur(e: FocusEvent) {
    e.stopPropagation();

    if (mounted.current && !checkbox) {
      window.setTimeout(() => {
        if (mounted.current) {
          setFocused(false);
        }
      }, 200);
      return props.onBlur?.(e);
    }
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter" && checkbox) {
      e.preventDefault();
      if (mounted.current) {
        if (e.currentTarget) {
          setChecked(!checked);
        }
        return props.handleKeyDown?.(e);
      }
    }
  }

  function checkboxIsDirty() {
    const { okay, required } = props;

    if ((checkbox && required && !checked) || (!required && checked && !okay)) {
      return true;
    }
    return false;
  }

  const type = props.type ?? "text";
  const dead = (props.disabled || props.readOnly) ?? false;

  const defaultClasses = `TextField TextField--${
    props.variant ? props.variant : "default"
  } TextField--type-${type} TextField--${dead ? "dead" : "alive"} ${
    checked === true ? "TextField--checked" : ""
  } TextField--${focused ? "is-focused" : "is-blurred"} ${
    props.okay !== undefined
      ? "TextField--" + (props.okay ? "okay" : "error")
      : ""
  }`;

  const placeholder = resolveCallback(props.placeholder, focused);

  const abstractProps = {
    role: props.role,
    "aria-label": props["aria-label"]
      ? props["aria-label"]
      : props["aria-labelledby"]
      ? props.name
      : undefined,
    "aria-labelledby": props["aria-labelledby"],
    "aria-describedby": props["aria-describedby"],
    disabled: props.disabled,
    id: props.id,
    maxLength: props.maxLength,
    name: props.name,
    pattern: props.pattern,
    placeholder: focused ? "" : placeholder,
    readOnly: props.readOnly,
    required: props.required,
    step: props.step,
    tabIndex: props.tabIndex,
    value: props.value ?? value,
    "data-qa-id": props["data-qa-id"] ?? props.id,
    "data-testid": props["data-testid"] ?? props.id,

    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
    onClick: handleOnClick,
  };

  // https://www.w3.org/TR/wai-aria-practices/examples/combobox/aria1.0pattern/combobox-autocomplete-list.html
  const htmlSearchBoxProps = {
    "aria-autocomplete": props["aria-autocomplete"],
    "aria-haspopup": props["aria-autocomplete"] === "list" ? true : undefined,
    "aria-activedescendant": props["aria-activedescendant"],
  };

  const htmlInputProps: {
    autoComplete: string;
    max?: string;
    min?: number | string;
    type?: string;
    "aria-invalid"?: boolean;
    onInput?: Props["onInput"];
  } = {
    autoComplete:
      typeof props.autocomplete === "string"
        ? props.autocomplete
        : props.autocomplete === false
        ? "off"
        : "on",
    max: props.max,
    min: props.min,
    type,
    ...htmlSearchBoxProps,
    onInput: props.onInput,
  };
  if (props.okay !== undefined) {
    htmlInputProps["aria-invalid"] = !props.okay;
  }

  const dirty = !!(props.value || value || checkboxIsDirty());
  const dirtyString = dirty ? "dirty" : "clean";

  const inputMarkup =
    type === "textarea" ? (
      <textarea
        ref={ref as RefObject<HTMLTextAreaElement>}
        className={`${defaultClasses} TextField--textarea TextField--${
          value ? "dirty" : "clean"
        }`}
        {...abstractProps}
        rows={props.rows}
        cols={props.cols}
        onChange={handleTextAreaChange}
      />
    ) : !props.icon ? (
      <input
        ref={ref as RefObject<HTMLInputElement>}
        className={`${defaultClasses} TextField--input TextField--${dirtyString}`}
        {...abstractProps}
        {...htmlInputProps}
        max={props.max ? props.max.toString() : ""}
        checked={checked}
      />
    ) : (
      <div
        className={`${defaultClasses} TextField--input TextField--has-icon TextField--icon-${
          props.incantation ? "right" : props.iconPlacement ?? "left"
        } TextField--${dirtyString}`}
      >
        <input
          {...abstractProps}
          {...htmlInputProps}
          max={props.max}
          checked={checked}
          ref={ref as RefObject<HTMLInputElement>}
        />
        <div className="TextField__icon">
          <FontAwesomeIcon
            aria-hidden="true"
            {...resolveCallback(props.iconProps, focused)}
            icon={resolveCallback(props.icon, focused)}
            onClick={() => {
              ref.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                ref.current?.focus();
              }
            }}
          />
        </div>
      </div>
    );

  useLayoutEffect(() => {
    if (
      props.type === "date" ||
      props.type === "text" ||
      props.type === "textarea" ||
      props.type === "number"
    ) {
      if (mounted.current) {
        setValue(props.value ? props.value : "");
      }
    }
    if (checkbox) {
      if (mounted.current) {
        setChecked(props.checked ? props.checked : false);
      }
    }
  }, [props.value, props.type, props.checked, checkbox]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      props.incantation?.({
        value,
        checked,
        focused,
        blurred: !focused,
        dirty,
        clean: !dirty,
        okay: props.okay ?? true,
        dead,
      });
    }
  }, [value, checked, focused, dirty, props.okay, dead]);

  return (
    <>
      {inputMarkup}
      {!checkbox && props.enableCharacterCount && (
        <span className="TextField__count">
          {`${value ? value.length : 0} / ${
            props.maxLength ? props.maxLength : 256
          }`}
        </span>
      )}
    </>
  );
}

interface CheckboxFieldProps extends Props, Omit<ArcaneProps, "field"> {}

export const CheckboxField = (props: CheckboxFieldProps) => (
  <div className={"checkboxField"}>
    <Arcane {...props} field={<TextField {...props} type="checkbox" />} />
  </div>
);

function resolveCallback<T = string>(
  value: T | ((active: boolean) => T),
  activated: boolean
): T {
  return value instanceof Function ? value(activated) : value;
}
