import trackMixpanelEvent from "SKNUI/mixpanel/mixpanel";
import { rendererIsClient } from "SKNUI/scripts";
import { A11yDialogInstance } from "./yodel";

export function openModal(target: string) {
  if (rendererIsClient()) {
    window.dispatchEvent(
      new CustomEvent("dialog:open", { detail: { target } })
    );
  }
}

export function closeModal(target: string) {
  if (rendererIsClient()) {
    window.dispatchEvent(
      new CustomEvent("dialog:close", { detail: { target } })
    );
  }
}

export function attachDOMTargets(id: string, callback: () => void) {
  const links = Array.prototype.slice.call(
    document.querySelectorAll(`[data-dialog-target="${id}"]`),
    0
  );
  links.forEach((link: Element) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      callback();
    });
  });
}

export function setFocus(dialog: A11yDialogInstance) {
  dialog?.$el?.querySelector<HTMLElement>("[focus]")?.focus();
}

export function bindCloseHandler(dialog: A11yDialogInstance) {
  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      const form = (e.target as HTMLInputElement).form;
      if (form) {
        const inputs = Array.prototype.slice.call(form.elements, 0);
        const invalids = inputs.filter(
          (input: HTMLInputElement): boolean => !input.validity.valid
        );

        if (invalids.length > 0) {
          e.preventDefault();
        }
      }
    }
  }
  // prevent dialog close if form has any invalid form fields
  dialog.$el.addEventListener("keydown", handleKeyDown);

  /** for side-effect cleanup */
  return handleKeyDown;
}

export function clearEventListeners(
  instance: A11yDialogInstance,
  closeHandler: undefined | ReturnType<typeof bindCloseHandler>
) {
  if (instance) {
    instance._listeners = {};
    if (closeHandler) {
      instance.$el.removeEventListener("keydown", closeHandler);
    }
  }
}

export function trackDialogEvent(trackingName: string, open: boolean) {
  trackMixpanelEvent(`Modal ${open ? "Launched" : "Closed"}`, {
    ...window.mixpanel_track_links_json,
    Modal: trackingName,
    Source: window.mixpanel_track_links_source,
  });
}
