import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import trackMixpanelEvent from "SKNUI/mixpanel/mixpanel";
import { openModal } from "SKNUI/yodel/utils";
import LeadGenModal, { modalID } from "./modal";
import { PFLogo } from "./pf-logo";
import { submitToPennFoster as api } from "./api";
import { Thumbnail } from "./thumbnail";
import { LeadGenUser, PartnerProgram, Submission } from "./interfaces";
import { User } from "SKNUI/interfaces/user";

export interface Props {
  partnerProgram: PartnerProgram;
  leadGenUser: LeadGenUser;
  userInfo: User;

  /**
   * a: both ctas
   * b: modal button only
   * c: external link only
   */
  abc?: "a" | "b" | "c";
}

export function LeadGen(props: Props) {
  const { abc = "c", userInfo } = props;
  const { isInUSA, locale, isTest, userSubmission } = props.leadGenUser;
  const { sentenceName, name, id, article, type, duration, link, slug } =
    props.partnerProgram;
  const [submitted, setSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);
  const [userState, setUserState] = useState<Submission | undefined>(
    userSubmission
  );
  const [userFname, setUserFname] = useState<string | undefined>(
    userState?.firstName ?? userInfo?.name
  );

  const [error, setError] = useState(false);

  function open() {
    openModal(modalID);
    trackMixpanelEvent("Lead Gen Open Modal", { name });
  }

  async function submit(submission: Submission) {
    setUserFname(submission.firstName);
    setUserState(submission);
    setBusy(true);

    if (!(await api({ id, name, submission, isTest }))) {
      setError(true);
      setBusy(false);
    } else {
      setError(false);
      setSubmitted(true);
      setBusy(false);
    }
  }

  const common = {
    submitted,
    submit,
    busy,
    error,
    userFname,
    userSubmission: userState,
    defaultLocale: locale,
    user: props.userInfo,
  };

  if (!isInUSA) {
    return null;
  }

  return (
    <div className="LeadGen Box">
      <LeadGenModal {...props} {...common} />
      <Thumbnail slug={slug} fade={true} />
      <PFLogo />
      <div className="LeadGen__content">
        <p className="LeadGen__p">
          <strong>Ready to take the next step in your career?</strong>
          {id === 7410
            ? " Explore construction trades through Penn Foster’s accredited online school."
            : ` Become ${article} ${
                sentenceName ? sentenceName : name
              } through Penn Foster's accredited online school.`}
        </p>
        <ul className="LeadGen__stats">
          <li>
            <strong>Type</strong> {type}
          </li>
          <li aria-hidden="true" className="LeadGen__stats__divide"></li>
          <li>
            <strong>Duration</strong> {duration[0]}–{duration[1]} months
          </li>
        </ul>
        <div className="LeadGen__actions">
          {(abc === "a" || abc === "b") && (
            <button
              onClick={open}
              className="alans-butt alans-butt--black"
              style={abc === "b" ? { gridColumn: "1 / -1" } : undefined}
            >
              {`Get free program info`}
            </button>
          )}
          {(abc === "a" || abc === "c") && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="alans-butt alans-butt--grey"
              style={abc === "c" ? { gridColumn: "1 / -1" } : undefined}
            >
              See program details
              <span className="LeadGen__actions__icon">
                <FontAwesomeIcon icon={faExternalLink} />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeadGen;
