import {
  ReactElement,
  useState,
  useEffect,
  useLayoutEffect,
  KeyboardEvent as ReactKeyboardEvent,
  ChangeEvent,
  FormEvent,
  SelectHTMLAttributes,
  useRef,
  AriaAttributes,
} from "react";
import { Arcana } from "SKNUI/arcane/arcane";
import TextField from "SKNUI/text-field/text-field";
import { Icon } from "@fortawesome/fontawesome-svg-core";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";

export const SELECT_FIELD_TIMEOUT = 180;

interface Props {
  "aria-label"?: AriaAttributes["aria-label"];
  "aria-labelledby"?: AriaAttributes["aria-labelledby"];
  "aria-describedby"?: AriaAttributes["aria-describedby"];
  autocomplete?: boolean | string;
  fieldClass?: string;
  id: string;
  okay?: boolean;
  items: SelectOptionItem[];
  /**
   * autocomplete reference list of `items`
   * if different/more comprehensive than `items`
   **/
  autocompleteItems?: SelectOptionItem[];
  placeholder?: string;

  autoFocus?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  name?: string;
  required?: boolean;
  value?: SelectHTMLAttributes<HTMLSelectElement>["value"];

  forceOpen?: boolean;
  autocompleteByInitialistId?: boolean;

  onSelect?: (item: SelectOptionItem | undefined) => void;
  onClear?: () => void;
  onFocus?: (e: FormEvent) => void;
  onBlur?: (e: FormEvent) => void;

  incantation?: (arcana: Arcana) => void;
}

export interface SelectOptionItem {
  id: number | string;
  name: string;
}

export default function SelectField(props: Props): ReactElement {
  const {
    autocomplete,
    disabled,
    fieldClass,
    id,
    items,
    autocompleteItems,
    okay,
    multiple,
    name,
    placeholder,
    onSelect,
    onClear,
    onFocus,
    onBlur,
    value,
    required,
    forceOpen = false,
    autocompleteByInitialistId = false,
    incantation,
  } = props;
  const mounted = useRef(false);
  const listbox = useRef<HTMLUListElement>(null);
  const [focused, setFocused] = useState(forceOpen);
  const [localValue, setLocalValue] = useState<Props["value"] | undefined>();

  // utility
  const [hidden, setHidden] = useState(true);
  const [cursor, setCursor] = useState(0);

  if (multiple) {
    throw new Error("'multiple' property not implemented");
  }

  function selectItem(item: SelectOptionItem | undefined) {
    setCursor(0);
    setFocused(false);
    if (item && item?.name !== localValue) {
      setLocalValue(item.name);
    }
  }

  function pick(item: SelectOptionItem | undefined) {
    onSelect?.(item);
    selectItem(item);
  }

  function handleEnterSelect(
    e: ChangeEvent<HTMLInputElement> | ReactKeyboardEvent
  ) {
    e.preventDefault();
    if (!disabled && focused && items.length > 0) {
      const item = items[cursor];
      if (item) {
        pick(item);
      }
    }
  }

  function handleKeyDown(e: ReactKeyboardEvent) {
    if (!disabled) {
      // Arrow Key Navigation
      if (e.key === "ArrowUp" && cursor > 0) {
        e.preventDefault();
        setCursor(cursor - 1);
      } else if (e.key === "ArrowDown") {
        if (cursor >= items.length - 1) {
          setCursor(0);
        } else {
          setCursor(cursor + 1);
        }
        // Enter Key
      } else if (e.key === "Enter") {
        if (cursor >= 0) {
          handleEnterSelect(e);
        }
        if (!focused) {
          setFocused(true);
        }
      } else {
        e.preventDefault();
        jumpToItem(e.key);
      }
    }
  }

  function jumpToItem(key: string) {
    const match = items.findIndex(
      (item) => item.name[0].toLowerCase() === key.toLowerCase()
    );
    if (match >= 0) {
      setCursor(match);
      const li = document.getElementById(`${id}-popup-${match}`);
      if (li) {
        listbox.current?.scrollTo(0, li.offsetTop - 25);
      }
    }
  }

  function handleEsc(e: KeyboardEvent) {
    if (mounted.current && !disabled && focused && e.key === "Escape") {
      onClear?.();
      selectItem(undefined);
    }
  }

  /**
   * Autocomplete hack.
   * Relies on [detect-autofill](https://github.com/matteobad/detect-autofill) to function
   */
  function handleAutocomplete(e: Event) {
    const input = e.target as HTMLInputElement;
    if (mounted.current && input.value) {
      if (!disabled) {
        if (input.hasAttribute("autocompleted")) {
          e.preventDefault();
          const list = autocompleteItems ?? items;
          const item =
            list?.find((it) => it.name === input.value) ??
            (autocompleteByInitialistId
              ? list?.find((it) => it.id === input.value)
              : undefined);
          if (item) {
            pick(item);
          }
        }
      }
    }
  }

  useEffect(() => {
    mounted.current = true;
    const field = document.getElementById(id);
    field?.addEventListener("onautocomplete", handleAutocomplete);
    return () => {
      mounted.current = false;
      field?.removeEventListener("onautocomplete", handleAutocomplete);
    };
  }, [id]);

  useEffect(() => {
    if (mounted.current && localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  // NOTE: used to set artificial delay to fight reactivity
  useLayoutEffect(() => {
    if (!focused) {
      window.setTimeout(() => {
        if (mounted.current) {
          setHidden(true);
        }
      }, SELECT_FIELD_TIMEOUT);
    } else {
      if (mounted.current) {
        setHidden(false);
      }
    }
  }, [focused]);

  useLayoutEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [focused]);

  const Field = (
    <TextField
      role="searchbox"
      id={id}
      data-testid={id}
      fieldClass="TextField TextField--child-of-SelectField"
      variant="selectfield"
      autocomplete={autocomplete}
      aria-autocomplete="list"
      aria-activedescendant={`${id}-popup-${cursor}`}
      disabled={disabled}
      type="text"
      name={name}
      placeholder={placeholder}
      aria-label={props["aria-label"]}
      aria-labelledby={props["aria-labelledby"]}
      aria-describedby={props["aria-describedby"]}
      required={required}
      onFocus={(e) => {
        if (!disabled) {
          onFocus?.(e);
          setFocused(true);
        }
      }}
      onBlur={(e) => {
        if (
          !disabled &&
          (e?.relatedTarget as HTMLElement)?.getAttribute("role") !== "listbox"
        ) {
          onBlur?.(e);
          setFocused(false);
        }
      }}
      okay={okay === false ? false : true}
      value={localValue?.toString()}
      icon={faSortDown as Icon}
      iconPlacement="right"
      tabIndex={0}
      incantation={incantation}
    />
  );

  return (
    <div
      role="combobox"
      aria-expanded={!hidden}
      aria-owns={`${id}-popup`}
      className={`SelectField SelectField--type-text SelectField--default SelectField ${
        "SelectField--" + (okay === false ? "error" : "okay")
      } SelectField--${disabled ? "dead" : "alive"} SelectField--${
        focused ? "is-focused" : "is-blurred"
      } SelectField--${value ? "dirty" : "clean"} ${
        okay !== undefined ? "SelectField--" + (okay ? "okay" : "error") : ""
      } ${fieldClass ? fieldClass : ""}`}
      onKeyDown={handleKeyDown}
      onMouseDown={(e) => {
        if (!disabled && (e.target as HTMLDivElement).id === id) {
          setFocused(!focused);
        }
      }}
      data-testid={`${placeholder ?? id}-combobox-select`}
    >
      {Field}
      <ul
        ref={listbox}
        role="listbox"
        className="Box SelectField__options"
        tabIndex={-1}
        id={`${id}-popup`}
        style={id === "education" ? { maxHeight: "200px" } : undefined}
      >
        {items.map((item, i) => (
          <li
            role="option"
            aria-selected={localValue === item.name}
            key={id + item.name + item.id}
            id={`${id}-popup-${i}`}
            onClick={() => {
              if (!disabled) {
                pick(item);
              }
            }}
            tabIndex={0}
            className={`SelectField__options__item ${
              cursor === i ? "SelectField__options__item--active" : ""
            }`}
          >
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
