import { cloneElement, ReactElement } from "react";
import ReactDOM from "react-dom";
import { User } from "SKNUI/interfaces/user";

export const rendererIsClient = (): boolean => {
  if (
    process.env.NODE_ENV !== "test" &&
    process.env.NODE_ENV !== "build" &&
    typeof window !== "undefined"
  ) {
    return true;
  }
  return false;
};

export const renderOrHydrate = (
  reactEl: ReactElement,
  domEl: HTMLElement
): void => {
  // This is kind of a dumb check but ¯\_(ツ)_/¯
  if (domEl.children.length === 0) {
    ReactDOM.render(reactEl, domEl);
  } else {
    ReactDOM.hydrate(reactEl, domEl);
  }
};

function convertStringToJSON(str: string) {
  if (str[0] === "{") {
    return JSON.parse(str.replace(/'/g, '"'));
  }
  if (str[0] === "[") {
    return JSON.parse(str);
  }
  if (str === "true" || str === "false" || str === "null") {
    return JSON.parse(str);
  }
  if (str === "undefined" || str === "None") {
    return undefined;
  }
  return str;
}

export function adaptDataset(dataset: HTMLOrSVGElement["dataset"]) {
  let ds: { [x: string]: string | boolean | number | undefined } = {
    ...dataset,
  };

  if (ds) {
    for (let [key, val] of Object.entries(ds)) {
      if (typeof val === "string") {
        ds[key] = convertStringToJSON(val);
      }
    }
  }

  return ds;
}

export function pickUserFromDjango() {
  if (typeof document === "undefined") {
    return { user: undefined };
  }
  let user: User | undefined;
  const dataset = document.getElementById("data-user-global")?.dataset;
  if (dataset) {
    user = adaptDataset(dataset) as unknown as User;
  } else if (rendererIsClient()) {
    console.warn(
      "`data-user-global` wasn't found on the DOM. This will have unknown side-effects rendering components using the `cloneAndRender` method"
    );
  }

  return { user };
}

function cloneAndRender(
  reactEl: ReactElement,
  container: HTMLElement,
  clearContainerInnerHTML = true
) {
  let ds = adaptDataset(container.dataset);
  const clone = ds
    ? cloneElement(reactEl, { ...ds, ...pickUserFromDjango() })
    : reactEl;
  if (clearContainerInnerHTML) {
    container.innerHTML = "";
  } // clear any skeleton loader before hydrating
  renderOrHydrate(clone, container);
}

/**
 * Used to reduce a common pattern
 */
export const renderToContainer = (
  reactEl: ReactElement,
  containerID: string,
  callback?: (container: HTMLElement) => void,
  clearContainerInnerHTML?: boolean
) => {
  if (containerID.includes("#")) {
    throw Error("getElementByID won't work if there's a hash #, silly.");
  }
  const container = document.getElementById(containerID);
  if (container) {
    cloneAndRender(reactEl, container, clearContainerInnerHTML);
    callback?.(container);
  }
};

/**
 * Used to reduce a common pattern
 */
export const renderAllToContainer = (
  reactEl: ReactElement,
  containerSelector: string,
  callback?: (container: HTMLElement) => void,
  clearContainerInnerHTML?: boolean
) => {
  document
    .querySelectorAll(containerSelector)
    ?.forEach((container: HTMLElement) => {
      cloneAndRender(reactEl, container, clearContainerInnerHTML);
      callback?.(container);
    });
};

export const getCookie = (name: string): string | null => {
  if (typeof document === "undefined") {
    return null;
  }
  const cookie = document.cookie;
  const setPos = cookie.search(new RegExp("\\b" + name + "="));
  const stopPos = cookie.indexOf(";", setPos);
  if (!~setPos) {
    return null;
  }
  let res;
  res = decodeURIComponent(
    cookie.substring(setPos, ~stopPos ? stopPos : undefined).split("=")[1]
  );
  return res.charAt(0) === "{" ? JSON.parse(res) : res;
};

/**
 * a type-safe method for attaching a component to 
 * a container without having to specify a bunch of 
 * garbage `defaultProps` (useful when Props are 
 * added further down the render process such as in 
 * `cloneAndRender` which scrapes Django context as HTML 
 * [data-attributes](https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes))
 * 
 * good example:
 * ```
 *  renderComponentToDOM(
      SharedResultsPage, // where Props = { member?: boolean; }
      "shared-results-container",
      { member: true }
    );
 * ```

 * bad example:
 * ```
 *  renderComponentToDOM(
      SharedResultsPage, // where Props = { member?: boolean; }
      "shared-results-container",
      { member: "true" } // (property) member?: boolean | undefined. Type 'string' is not assignable to type 'boolean | undefined'
    );
 * ```
 * 
 * @param Component as uninstantiated `ReactElement`
 * @param containerID id of target DOM container 
 * @param propsFromEntry derived `Props` from the passed uninstantiated `Component`
 * @param callback fires on render of the `Component`
 * @param clearContainerInnerHTML whether or not to remove the innerHTML to clear loader before rendering DEAFAULT IS TRUE (mainly used to fix /jobs)
 */
export function renderComponentToDOM<P extends object>(
  Component: (props: P) => ReactElement | null,
  containerID: string,
  /**
   * User contract is fulfilled via `data-user-global`,
   * no need to pass in user here
   **/
  propsFromEntry?: Omit<Partial<Parameters<typeof Component>[0]>, "user">,
  callback?: (container: HTMLElement) => void,
  clearContainerInnerHTML?: boolean
) {
  try {
    renderToContainer(
      <Component {...(propsFromEntry as P)} />,
      containerID,
      callback,
      clearContainerInnerHTML
    );
  } catch (error) {
    throw new Error(`[${containerID} render error]: ` + error);
  }
}
