import { CSSProperties, ReactNode, useLayoutEffect, useState } from "react";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Tooltip from "SKNUI/tooltip/tooltip";
import SokanuLogo from "Static/components/sokanu-logo";
import { PFLogoSVG } from "./pf-logo";

interface Props {
  className?: string;
  name?: string;
  text?: string | ReactNode;
}

export const TT = ({
  className = "LeadGenModal",
  name = "Partner",
  text = defaultText,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  useLayoutEffect(() => {
    const tt = document.getElementById("tooltip-modal-form");
    const ttbox = tt?.getBoundingClientRect();
    if (tt && ttbox?.left !== undefined && ttbox?.left < 0) {
      tt.style.left = `calc(50% + ${Math.abs(
        ttbox.left
      )}px + var(--tooltipParentOffset, 2px))`;
    }
  });

  return (
    <div
      className={`${className}__tooltip`}
      style={{ "--parentWidth": `25px` } as CSSProperties}
    >
      <button
        onClick={() => setExpanded(!expanded)}
        className="alans-butt alans-butt--black"
      >
        {name}
        <FontAwesomeIcon icon={faQuestionCircle} />
      </button>
      {expanded && (
        <Tooltip
          id="modal-form"
          expanded={true}
          position="center-bottom"
          text={
            <>
              <div className="Tooltip__top">
                <SokanuLogo className="ce-logo" />
                <span className="divide" />
                <PFLogoSVG />
              </div>
              <p className="Tooltip-text" data-testid="tooltip-text">
                {text}
              </p>
            </>
          }
          animation="slide"
          popover={{ delay: 0, duration: Infinity }}
        />
      )}
    </div>
  );
};

export const defaultText = (
  <>
    Penn Foster and CareerExplorer have partnered to create career pathway
    opportunities through connection to online training for those seeking a new
    career.{" "}
    <a
      href="https://www.pennfoster.edu/why-penn-foster/?adkey=PKUA00V"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn more about Penn Foster.
    </a>
  </>
);
