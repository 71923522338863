const bucket = "https://res.cloudinary.com/hnpb47ejt/image/upload/";

export const PFLogo = () => (
  <img
    className="LeadGen__icon"
    src={bucket + "v1645749229/eg4w8zlukxpzluoojy5m.png"}
    alt="Penn Foster Logo"
  />
);

export const PFLogoSVG = () => (
  <svg
    width="102"
    height="39"
    viewBox="0 0 102 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="LeadGen__icon"
    aria-label="Penn Foster Logo"
  >
    <g clipPath="url(#clip0_159_15835)">
      <path
        d="M41.513 9.43647C41.513 8.24415 40.813 7.67138 39.3775 7.67138H35.4324V11.2136H39.3775C40.813 11.2136 41.513 10.6407 41.513 9.43647ZM43.4856 9.43647C43.4856 11.6577 42.1201 12.8617 39.6458 12.8617H35.4324V16.8128H33.5186V6.0232H39.6575C42.1201 6.0232 43.4856 7.22727 43.4856 9.43647Z"
        fill="white"
      />
      <path
        d="M47.0801 7.67138V10.5471H53.6857V12.1955H47.0801V15.1646H54.7245V16.8128H45.166V6.0232H54.7245V7.67138H47.0801Z"
        fill="white"
      />
      <path
        d="M66.9894 6.02304V16.8244H65.1918L58.5626 8.18554V16.8244H56.7188V6.02304H59.123L65.1335 13.8552V6.02304H66.9894Z"
        fill="white"
      />
      <path
        d="M79.8628 6.02304V16.8244H78.0653L71.4363 8.18554V16.8244H69.6035V6.02304H72.0079L78.0186 13.8552V6.02304H79.8628Z"
        fill="white"
      />
      <path
        d="M35.4324 23.99V27.0061H42.0383V28.6543H35.4324V33.1314H33.5186V22.3301H43.0771V23.9785H35.4324V23.99Z"
        fill="white"
      />
      <path
        d="M53.6504 27.7311C53.6504 25.4165 52.0632 23.862 49.7056 23.862C47.3595 23.862 45.7606 25.4165 45.7606 27.7311C45.7606 30.0458 47.3595 31.6005 49.7056 31.6005C52.0632 31.6123 53.6504 30.0575 53.6504 27.7311ZM43.7998 27.7311C43.7998 24.3764 46.1577 22.1436 49.6939 22.1436C53.2419 22.1436 55.6111 24.3878 55.6111 27.7311C55.6111 31.0862 53.2535 33.3189 49.6939 33.3189C46.1691 33.3189 43.7998 31.0862 43.7998 27.7311Z"
        fill="white"
      />
      <path
        d="M56.7422 31.764L57.5709 30.2443C58.8429 31.1445 60.3951 31.6471 62.0526 31.6471C64.0484 31.6471 65.1103 31.1093 65.1103 30.0456C65.1103 29.1571 64.3284 28.8064 61.9359 28.631C58.4812 28.3623 57.2091 27.5207 57.2091 25.4748C57.2091 23.3943 58.9713 22.155 61.9474 22.155C63.7213 22.155 65.227 22.5991 66.4526 23.4527L65.6939 24.9487C64.6434 24.2008 63.3597 23.8384 61.9359 23.8384C60.2086 23.8384 59.1581 24.4111 59.1581 25.3931C59.1581 26.2814 59.9168 26.6321 62.3092 26.8075C65.7754 27.0766 67.0476 27.9181 67.0476 29.9637C67.0476 32.1497 65.2854 33.3305 62.0526 33.3305C59.9751 33.3188 58.1543 32.7809 56.7422 31.764Z"
        fill="white"
      />
      <path
        d="M78.2395 23.99H74.0729V33.1314H72.1587V23.99H67.9922V22.3419H78.2515V23.99H78.2395Z"
        fill="white"
      />
      <path
        d="M81.7179 23.99V26.8657H88.3235V28.5138H81.7179V31.4832H89.3627V33.1314H79.8037V22.3301H89.3627V23.9785H81.7179V23.99Z"
        fill="white"
      />
      <path
        d="M93.2849 27.2518H97.5335C98.8754 27.2518 99.5291 26.7139 99.5291 25.6151C99.5291 24.5162 98.8754 23.9787 97.5335 23.9787H93.2849V27.2518ZM99.7158 33.1315L96.7631 28.9117H93.2736V33.1315H91.3594V22.3302H97.8016C100.183 22.3302 101.478 23.4875 101.478 25.6151C101.478 27.3569 100.591 28.4555 98.9568 28.7947L102.004 33.1315H99.7158Z"
        fill="white"
      />
      <path
        d="M12.591 24.3347L9.61496 24.3464L8.51293 24.3546L8.5108 29.1404H2.75431V9.86436H8.50639V9.86696H9.14835H12.276C16.8862 9.89048 19.4317 13.7596 19.4491 17.1074C19.4668 20.4554 16.8672 24.1753 12.591 24.3347ZM8.50639 0.00298691V7.55863H2.75431V7.55101H0.260742V31.4378H2.75431V31.4333H8.5098L8.50639 38.9999L24.2739 32.8511V6.15181L8.50639 0.00298691Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_159_15835">
        <rect
          width="101.739"
          height="39"
          fill="white"
          transform="translate(0.260742)"
        />
      </clipPath>
    </defs>
  </svg>
);
