import { AriaRole } from "react";

const bucket = "https://res.cloudinary.com/hnpb47ejt/image/upload/";
const images = [
  ["mobile", ""],
  ["desktop", "thumbnails/"],
];

interface Props {
  slug?: string;
  fade?: boolean;
  alt?: string;
  role?: AriaRole;
  className?: string;
}

export function Thumbnail({
  slug,
  fade = false,
  className = "",
  alt = "",
  role = "presentation",
}: Props) {
  const common = { alt, role };
  return (
    <div className={`LeadGen__thumb ${className}`}>
      {slug &&
        images.map(([c, path]) => (
          <img
            {...common}
            key={c}
            className={`LeadGen__thumb__${c}`}
            src={bucket + `v1646258562/lead-gen/${path + slug}`}
          />
        ))}
      {!slug &&
        images.map(([c]) => (
          <div
            {...common}
            key={c}
            className={`LeadGen__thumb__${c}`}
            style={{
              background:
                "conic-gradient(from 135deg at 50% 50%, #FF8181 0deg, #FFC670 360deg)",
            }}
          />
        ))}
      {fade && <div className="LeadGen__thumb__fade" />}
    </div>
  );
}
