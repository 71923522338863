import { Dispatch, useState, useLayoutEffect } from "react";

type FieldValue = string | undefined;
export type InputTypes = "number" | "email" | "password" | "custom";

/**
 * Generic useInput function for validation of input state.
 * (note: Intentionally left out `<input type=text>` in favor of case-by-case validation via `useCustomInput`)
 * @param defaultState default for useState
 * @param optOut can be used to toggle validation on/off
 * @param callback function returned agnostic to validation (good for external business cases)
 * @param type html input type or "custom" (used with customValidator)
 * @param customValidator used with type === "custom"
 * @returns [state, setState, error]
 */
function useInput<T>(
  defaultState: T,
  optOut = false,
  callback?: (param: T) => void | (() => void | undefined),
  type?: InputTypes,
  /**
   * return `true` for no errors, `false` for errors present
   */
  customValidator?: (state: T) => boolean
): [T, Dispatch<T>, boolean] {
  const [state, setState] = useState<T>(defaultState);
  const [valid, setValid] = useState(true);

  useLayoutEffect(() => {
    if (optOut) {
      setValid(true);
    } else if (type) {
      switch (type) {
        case "number":
          setValid(validateNum(state));
          break;
        case "email":
          setValid(validateEmail(state));
          break;
        case "password":
          setValid(validatePassword(state));
          break;
        case "custom":
          setValid(customValidator ? customValidator(state) : true);
          break;
        default:
          throw new Error(`input type ${type} is not supported`);
      }
    }

    if (callback) {
      callback(state);
    }
  }, [state, callback, type, customValidator, optOut]);

  return [state, setState, valid];
}

export function validateNum<T>(number: T): boolean {
  return number ? !isNaN(+number) : true;
}

export function validateEmail<T>(email: T): boolean {
  if (typeof email === "number") {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email ? re.test((email as unknown) as string) : true;
}

export function validatePassword<T>(password: T): boolean {
  return password ? ((password as unknown) as string).length >= 6 : true;
}

export const useNumberInput = (
  defaultState: FieldValue,
  optOut = false,
  callback?: (param: FieldValue) => void | (() => void | undefined)
): [FieldValue, Dispatch<FieldValue>, boolean] =>
  useInput<FieldValue>(defaultState, optOut, callback, "number");

export const useEmailInput = (
  defaultState: FieldValue,
  optOut = false,
  callback?: (param: FieldValue) => void | (() => void | undefined)
): [FieldValue, Dispatch<FieldValue>, boolean] =>
  useInput<FieldValue>(defaultState, optOut, callback, "email");

export const usePasswordInput = (
  defaultState: FieldValue,
  optOut = false,
  callback?: (param: FieldValue) => void | (() => void | undefined)
): [FieldValue, Dispatch<FieldValue>, boolean] =>
  useInput<FieldValue>(defaultState, optOut, callback, "password");

export const useCustomInput = <T>(
  defaultState: T,
  optOut = false,
  /**
   * return `true` for no errors, `false` for errors present
   */
  customValidator: (state?: T) => boolean,
  callback?: (param: T) => void | (() => void | undefined)
): [T, Dispatch<T>, boolean] =>
  useInput<T>(defaultState, optOut, callback, "custom", customValidator);

export default useInput;
