/* ============================================

  Arcane ⚗️

  A deliberate abstraction of form labels
  to make accessibility great again.

  Don't need any form labels, errors,
  or disclaimers? Don't use this.

============================================ */

import { ReactElement, FunctionComponent, cloneElement, useState } from "react";
import { Props } from "SKNUI/text-field/text-field";
export interface ArcaneProps {
  label?: string;
  disclaimer?: string;
  error?: string;
  field: ReactElement<Props>;
  /** Triggers classic non-layout fragment mode */
  contentsOnly?: boolean;
}

/**
 * A deliberate abstraction of input labels for make accessibility good.
 *
 * *Now with field states!*
 */
export const Arcane: FunctionComponent<ArcaneProps> = ({
  field,
  label,
  disclaimer,
  error = "Invalid input",
  contentsOnly = false,
}) => {
  const [arcana, castArcana] = useState<Arcana>();
  const spells: [string, boolean][] = arcana
    ? Object.entries(arcana).filter(
        ([key]) => key !== "value" && key !== "okay"
      )
    : [];

  if (!label && !error && !disclaimer) {
    throw new Error(
      "[Arcane Needs Props]: One of the following props must be provided: label, error, dislaimer."
    );
  }

  const disclaimerId = "disclaimer-" + (field.props.id ?? field.props.name);
  const labelId = "label-" + (field.props.id ?? field.props.name);

  function buildAria() {
    let desc = [];
    if (field.props["aria-describedby"]) {
      desc.push(field.props["aria-describedby"]);
    }
    if (disclaimer) {
      desc.push(disclaimerId);
    }
    return desc.length > 0 ? desc.join(" ") : undefined;
  }

  const child = cloneElement(field, {
    "aria-describedby": buildAria(),
    "aria-labelledby": label ? labelId : undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    incantation: !contentsOnly ? castArcana : () => {},
  });

  const inputType = field.props.type ?? "text";
  const checkbox = inputType === "checkbox" || inputType === "radio";
  const labelMarkup = label && (
    <label
      id={labelId}
      className="Label"
      htmlFor={field.props.id}
      tabIndex={-1}
    >
      {label}
    </label>
  );

  const arcaneClasslist = `Arcane--${
    contentsOnly || checkbox ? "contents-only" : "block"
  } Arcane--${arcana?.okay ? "okay" : "error"} ${spells
    .map(([key, value]) => (value ? `Arcane--${key}` : ""))
    .join(" ")}`;

  return (
    <div
      className={`Arcane ${arcaneClasslist}`}
      data-display-mask={getDisplayMask(field.props, arcana?.value)}
    >
      {!checkbox && labelMarkup}
      {child}
      {checkbox && labelMarkup}
      {error && (
        <span className="Error" role="alert">
          {error}
        </span>
      )}
      {disclaimer && (
        <span className="Disclaimer" id={disclaimerId}>
          {disclaimer}
        </span>
      )}
    </div>
  );
};

export default Arcane;

function getDisplayMask(fieldProps: Props, value?: string) {
  const useDisplayValue =
    !(fieldProps.type === "checkbox" || fieldProps.type === "radio") &&
    value &&
    fieldProps.displayMaskRegExp &&
    fieldProps.displayMaskRegExpReplace;

  return useDisplayValue &&
    fieldProps.displayMaskRegExp &&
    fieldProps.displayMaskRegExpReplace &&
    fieldProps.displayMaskRegExp.test(value)
    ? value.replace(
        fieldProps.displayMaskRegExp,
        fieldProps.displayMaskRegExpReplace
      )
    : undefined;
}

/**
 * For upstream communication between a `TextField` and `Arcane`
 */
export interface Arcana {
  value: string | undefined;
  checked: boolean;
  focused: boolean;
  blurred: boolean;
  dead: boolean;

  dirty: boolean;
  clean: boolean;
  okay: boolean;
}
