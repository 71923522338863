import {
  ReactNode,
  useEffect,
  cloneElement,
  ReactElement,
  useState,
  useCallback,
} from "react";
import { createPortal } from "react-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass } from "@fortawesome/pro-regular-svg-icons/faCompass";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import A11yDialog from "a11y-dialog";
import { A11yDialogProps, useA11yDialog } from "react-a11y-dialog";
import {
  attachDOMTargets,
  bindCloseHandler,
  clearEventListeners,
  setFocus,
  trackDialogEvent,
} from "./utils";

/** allows TS to access hidden props */
export interface A11yDialogInstance extends A11yDialog {
  $el: HTMLDivElement;
  shown: boolean;
  _listeners: {
    hide?: (() => void)[];
    show?: (() => void)[];
  };
}

interface Props extends Omit<A11yDialogProps, "title"> {
  loading?: boolean;
  baseModifierClass?: string;
  dialogModifierClass?: string;
  wrapModifierClass?: string;
  trackingName?: string;

  title?: string | ReactNode;
  icon?: IconDefinition;

  closePrompt?: boolean;

  onDialogShow?: () => void;
  onDialogHide?: () => void;

  children: ReactNode;
}

/**
 * 🏔🗣 Y.O.D.E.L.
 *  Yeet
 *  Old
 *  Dialog with
 *  Exuberant
 *  Libation
 *
 * a less reffy dialog
 */
export function Yodel({
  id,
  role = "dialog",
  loading,
  baseModifierClass = "",
  dialogModifierClass = "",
  wrapModifierClass = "",
  trackingName,
  title,
  children,
  icon,
  closePrompt,
  onDialogShow,
  onDialogHide,
}: Props) {
  const [a11y, attr] = useA11yDialog({ id, title, role });
  const instance = a11y as A11yDialogInstance;
  const [prompt, setPrompt] = useState(false);
  const canHide = !closePrompt || (closePrompt && prompt);

  const classes = {
    container: `Dialog-container ${baseModifierClass}`,
    overlay: "Dialog-backdrop",
    dialog: loading ? "Dialog-hidden" : `Dialog ${dialogModifierClass}`,
    inner: `Dialog-wrap ${wrapModifierClass}`,
    title: "Dialog-title",
    closeButton: "Dialog-close",
  };

  const hide = useCallback(() => {
    if (instance) {
      if (canHide) {
        instance.hide();
        trackDialogEvent(trackingName ?? "None", false);
      } else {
        setPrompt(true);
      }
    }
  }, [instance, canHide, trackingName]);

  useEffect(() => {
    let closeHandler: undefined | ReturnType<typeof bindCloseHandler>;

    function launch() {
      if (instance) {
        instance.show();
        setFocus(instance);
        closeHandler = bindCloseHandler(instance);
        trackDialogEvent(trackingName ?? "None", true);
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.add("dialog-open");
      }
    }
    function open(e: CustomEvent) {
      if (id === e.detail.target) {
        launch();
      }
    }
    function close(e: CustomEvent) {
      if (id === e.detail.target) {
        hide();
      }
    }

    if (instance) {
      instance.on("show", () => {
        onDialogShow?.();
      });
      instance.on("hide", () => {
        setPrompt(false);
        onDialogHide?.();
        document
          .getElementsByTagName("body")?.[0]
          ?.classList.remove("dialog-open");
        clearEventListeners(instance, closeHandler);
      });
    }

    if (instance && id) {
      window.addEventListener("load", () => attachDOMTargets(id, launch));
      window.addEventListener("dialog:open", open);
      window.addEventListener("dialog:close", close);
    }
    return () => {
      window.removeEventListener("dialog:open", open);
      window.removeEventListener("dialog:close", close);
      clearEventListeners(instance, closeHandler);
    };
  }, [instance, hide, id, onDialogShow, onDialogHide, trackingName]);

  const titleProps = {
    ...attr.title,
    className: `dialog-title ${classes.title}`,
  };

  const markup = (
    <div
      {...attr.container}
      className={`yodel dialog-container ${classes.container}`}
      // fix for 'Broken ARIA reference' when no title present
      aria-labelledby={title ? attr.container["aria-labelledby"] : ""}
    >
      <div
        {...attr.overlay}
        className={`dialog-overlay ${classes.overlay}`}
        onClick={() => hide()}
      />
      <div {...attr.dialog} className={`dialog-element ${classes.dialog}`}>
        <div className={`dialog-inner ${classes.inner}`}>
          <button
            onClick={() => hide()}
            className={`dialog-close ${classes.closeButton}`}
            title="Close"
            type="button"
            aria-label="Close this dialog window"
            tabIndex={-1}
          >
            <FontAwesomeIcon
              icon={icon ?? faTimes}
              className="Dialog-close-icon"
            />
          </button>
          <div style={prompt ? { pointerEvents: "none" } : undefined}>
            {title &&
              (typeof title === "string" ? (
                <p {...titleProps}>{title}</p>
              ) : (
                cloneElement(title as ReactElement, {
                  ...titleProps,
                  ...(title as ReactElement).props,
                  // messy merge to not overwrite incoming className
                  ...() =>
                    (title as ReactElement).props.className
                      ? {
                          className:
                            (title as ReactElement).props.className +
                            titleProps.className,
                        }
                      : {},
                })
              ))}
            {loading && (
              <div className="Dialog-loader" data-testid="dialog-loading">
                <FontAwesomeIcon icon={faCompass} spin />
              </div>
            )}
            {children}
          </div>
          {prompt && (
            <div className="dialog-prompt">
              <p {...titleProps}>{"Are you sure you want to cancel?"}</p>
              <p>Your information here will not be saved if you leave.</p>
              <div className="dialog-prompt__actions">
                <button className="alans-butt--black" onClick={() => hide()}>
                  Yes, close
                </button>
                <button
                  className="alans-butt--grey"
                  onClick={() => setPrompt(false)}
                >
                  Cancel and take me back
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return process.env.NODE_ENV !== "test" && typeof document !== "undefined"
    ? createPortal(markup, document.querySelector("#dialog-portal") as Element)
    : markup;
}

/**
 * In case you didn't want to `Yodel` everywhere
 */
const Dialog = Yodel;
export default Dialog;

/**
 * shorthand for `role=alertdialog`
 */
export const Modal = (props: Props) => <Yodel {...props} role="alertdialog" />;

//                               #* (*,(((/,,(#      %
//                            .,((((((((((/. ...*(*,%
//                             *.((((/.  .(((.,/((((/**%.
//                        &%,(@@@@&#*(((.,((((((((((((((/((*,
//                    /,/%&&&&#/*(((.*(((((((((((((((((((((((((
//                           ((((.(((((((((((((((((((((((((/(((/
//                             */(/((((((((((.,(((((((((((((((,/#&
//                            */((/(((((((,/((((((((((((((((/(((((%
//                           #,(((/(((((((((((%@@/((((((((/%@@#(#((*
//                           .(((((/((((((((((&@@@#(((((((*@@@@,(#(/
//                          &,((((((((((((((((/#....((((((.   .,((((*
//                          *,((((((/(((((((((*     ((((((*    .(((#/
//                          ,*((((((/((((((((((,    ((((((/.   /(#(/((
//                          ,,((((((/((/((((((((/. .(((((((((./((((/(/
//                          /,((((((/((/((((((((((((((((((((((((((/,(/*/&*.
//                          %,((((((/(((((((((((((((((/./,,((((#((((*(*@@(/                                 /&@&(#*(*/*/
//                           .((((((/(((((((((((((*#&&/*@@@#,((((((*@@@/(#                         ,@@@  (,,&@@@@@@@@@&,&
//                           *,((((((/((((((((*%#@@@&&@@@@@@@@,@@@%,*,,                            ((*   &,@@@@@@@@@@@@@#%
//                           @.((/((((/((((((((/,##,&@@&#*,*%@@(/((((((                            ./.(  (&#/@@@@@@@@*&,
//                           .,*((((((((((((((((((((/.*********,((((((,%                           *((*/,&&#/.*@@@,(((,
//                            #,((((((*((((((((((((((((.,***, *(((((((,,*                         %,(,%,*./((//.@.((((**
//                            .,/((((((/((((((((((((((((/((((/((((((((*(#                        *((((((.(*%%%*(,,(((((,
//                            @*/,.,*((((((((((((((((((((((((((((((((/((//,..,*/#//(       (////,((((.((.%.(((/*(((((((*&
//                            #*//(((((((/.*(((((/(((((((((((((((((#(,((/((((((((((((((((((((((((((((*((/,/,/%%.((((((((./
//                    (/..*(((((((((((..*(((((/.(/(((((((((((((/(((((*(/(((((((((((((((((#((((((((((((((/ #/..  .(.(((/(/,
//                #,./(((((((((((((((((((((/.,(((,((((((((((((((/(((*((/((((((((((((//*((((((/,**,,..*,*#.*....,.((*(/.,..*
//             ***((((((((((/((((((((((((((((((,/((,(((((((/,/(/*/((,((/.*/////*/*,..,,/(/                (((   ....,*(#(.
//           %,((((((((*(((((((((((((((((((((((((.(((,((((,%%#(###(((((/*
//          */((((((/((((,.,(%%(,..,((((((((((((((,(((/(((##(##%###((((/*
//        *,(((((((((*,%         (.((((((/(((((((((.(((((/,#(#(#%%,((*((%
//       .,(/((((((*,*           /,*((((((((((((((((.((,(*((/.,,,((/(,(((
//       *((((((#(.%              *.((((((*(((((((((.((/(((((((#(((((*((,
//      (((((((((,,               %.((((((((((((((((*/((/(/(((((((((((*(*&
//     %*(((((((,                 (,/(((((*(((((((((/,((*(/((((((((/((,(/,.
//    */(((((((,.                  *.(((((*((((((((((,((.(((((((((((/(*((/%
//   ,(((((((((((.*///             #.(((((/((((((((((.((.(/(((((((((/(/((,.
//  */*.*/,((/(,.,..,/             (./(((((/(((((((((.((,(/(((((/((((((*(.,&
//  ,(**(..(...(,                  #,*((((((/((((((((.((//((((((/((((((,(,(,
//  %,&.(*/(,%*(,*                  ,.(((((((/((((((( (((,((/,..,,.,....(*(.
//      # .*,* ,&                   *.((((((((((*,...,(((.((,/(((((((,,(*(((,
//                                /,.....,/(((((((((((((//((((((((((((((*(((*
//                                 ,(((((((((((((((((((((/((#%*/(((/**##,(((*
//                                 (/(((((((((((((((((((((*((#(/##(#((#(*(((,
//                                  ,(((((((((((((((((((((*(((#*((((/%((*(((*
//                                   (/(((((((((((((((((((*###,(((((/###*(((@
//                                     */(((((((((((((((((/..,(((#(#((,/(((,
//                                    &/(##(((((((((((((((((((((##(#((((((.,
//                                    *%%#(((((((((((((((((((((((#(((((((((/(
//                                   %(%#((((((((((((((((((((((((((/(((((((((,.
//                                   #.,.,. ..((((((((((((((((/,,*((((((/...,.,
//                                   %,*((((((((,.(((,, .,,,*   *(((,.*(((((((/
//                                   //((((((((((. .(            #,,/(((((/((((,
//                                   ,/((((((/((*(                 ,,(/((((((((*
//                                  #/((((((((((/                    (/((((((((((
//                                 /,**/((((((((,                     #/*%@@@@@@@*
//                                 /,(%&@@@@@&(*#                    *,@@#/&@@&&#/*
//                                (,,%@@@@@@#,%(*                      (*@@@@@@@/,(
//                                ,%(/@@@@@@@@**                        *&@@&&%%,%(
//                                 *,.    ....,                         ,.   .. ..(
//                            @#,.            ./                      &.        ......,,*@  ,(
//                        %,   .              ..,                     %.        . .      .,,(
//                       *..         . . #.  ..,                       ,...., /,...      ..,.//,
//                      #... . .   ..,#                                    #    @#*,.....,,,,(
