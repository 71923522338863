import { ReactElement, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/pro-duotone-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/pro-duotone-svg-icons/faThumbsDown";
import { faHandPeace } from "@fortawesome/pro-regular-svg-icons/faHandPeace";

import { trackMixpanelEvent } from "SKNUI/mixpanel/mixpanel";

interface DOMFeedbackMap extends Props {
  [name: string]: string | undefined;
}

export interface HTMLFeedbackComponent extends Omit<HTMLDivElement, "dataset"> {
  dataset: DOMFeedbackMap;
}

interface Props {
  careerName: string;
  careerSectionSlug: string;
}

export default function FeedbackButtons(props: Props): ReactElement {
  let [feedbackComplete, setFeedbackComplete] = useState(false);

  function handleMixPanel(isGood: boolean): void {
    trackMixpanelEvent("Career Section Feedback", {
      "Feedback Is Positive?": isGood,
      Career: props.careerName,
      "Career Section": props.careerSectionSlug,
    });
  }

  function handleUpDoot(): void {
    handleMixPanel(true);
    setFeedbackComplete(true);
  }

  function handleDownDoot(): void {
    handleMixPanel(false);
    setFeedbackComplete(true);
  }

  return (
    <div
      className="CareerSectionFeedback"
      aria-live="assertive"
      aria-atomic="true"
      aria-relevant="text"
    >
      {!feedbackComplete ? (
        <>
          <span className="CareerSectionFeedback-text" tabIndex={0}>
            Was this helpful?
          </span>
          <button
            className="CareerSectionFeedback-button alans-butt--grey"
            onClick={(): void => handleUpDoot()}
            tabIndex={0}
            aria-label="Option one: this career information was interesting. Click here to provide feedback."
            title="interesting"
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="CareerSectionFeedback-button__icon"
            />
          </button>
          <button
            className="CareerSectionFeedback-button alans-butt--grey"
            onClick={(): void => handleDownDoot()}
            tabIndex={0}
            aria-label="Option two: this career information was not interesting. Click here to provide feedback."
            title="not interesting"
          >
            <FontAwesomeIcon
              icon={faThumbsDown}
              className="CareerSectionFeedback-button__icon"
            />
          </button>
        </>
      ) : (
        <>
          <span className="CareerSectionFeedback-text" tabIndex={0}>
            Thanks for your feedback!
          </span>
          <span
            className="CareerSectionFeedback-button alans-butt--grey"
            aria-hidden="true"
          >
            <FontAwesomeIcon
              icon={faHandPeace}
              className="CareerSectionFeedback-button__icon"
            />
          </span>
        </>
      )}
    </div>
  );
}
